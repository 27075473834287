export const GET_ALL_PC_REQUEST = 'GET_ALL_PC_REQUEST';
export const GET_ALL_PC_SUCCESS = 'GET_ALL_PC_SUCCESS';
export const GET_ALL_PC_FAILURE = 'GET_ALL_PC_FAILURE';

export const GET_USERS_PC_REQUEST = 'GET_USERS_PC_REQUEST';
export const GET_USERS_PC_SUCCESS = 'GET_USERS_PC_SUCCESS';

export const START_SESSION_REQUEST = 'START_SESSION_REQUEST';
export const START_SESSION_SUCCESS = 'START_SESSION_SUCCESS';

export const END_SESSION_REQUEST = 'END_SESSION_REQUEST';
export const END_SESSION_SUCCESS = 'END_SESSION_SUCCESS';

export const CLEAN_PC_USERS_SEARCH_LIST = 'CLEAN_PC_USERS_SEARCH_LIST';

export const TRANSFER_USER_REQUEST = 'TRANSFER_USER_REQUEST';
export const TRANSFER_USER_SUCCESS = 'TRANSFER_USER_SUCCESS';

export const EDIT_PC_REQUEST = 'EDIT_PC_REQUEST';
export const EDIT_PC_SUCCESS = 'EDIT_PC_SUCCESS';
// export const EDIT_PC_FAILURE = 'EDIT_PC_FAILURE';

export const ARRANGE_PC_REQUEST = 'ARRANGE_PC_REQUEST';
export const ARRANGE_PC_SUCCESS = 'ARRANGE_PC_SUCCESS';

export const WS_INITIALIZE_CHANNEL = 'WS_INITIALIZE_CHANNEL';

export const WS_SESSION_STARTED = 'WS_SESSION_STARTED';
export const WS_SESSION_STARTED_SUCCESS = 'WS_SESSION_STARTED_SUCCESS';

export const WS_SESSION_FINISHED = 'WS_SESSION_FINISHED';
export const WS_SESSION_FINISHED_SUCCESS = 'WS_SESSION_FINISHED_SUCCESS';

export const WS_STATUS_CHANGED = 'WS_STATUS_CHANGED';
export const WS_STATUS_CHANGED_SUCCESS = 'WS_STATUS_CHANGED_SUCCESS';

export const ARRANGE_PC_MODE = 'ARRANGE_PC_MODE';
