import { color } from '../theme';

const MuiSwitch = {
  root: {
    marginLeft: 'auto',
    marginRight: '-12px',
  },
  track: {
    backgroundColor: color.lightBlue,
  },
  colorSecondary: {
    '&$checked': {
      color: color.white,
    },
    '&$checked + $track': {
      backgroundColor: color.green,
      opacity: 1,
    },
  },
};

export default MuiSwitch;
