import * as types from './types';
import * as typesComputer from '../../computers/state/types';

const initialState = {
  loading: false,
  loadingNoteAdd: false,
  error: false,
  currentUser: {},
  users: {
    data: [],
    meta: {
      'x-total': 0,
    },
    loading: false,
  },
  invoice: {},
  refills: [],
};

// we cant store users as object by id because of infinity scroll lib
const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // case types.SEARCH_ALL_USERS_REQUEST:
    // case types.ALL_USERS_REQUEST: {
    //   return {
    //     ...state,
    //     users: {
    //       ...state.users,
    //     },
    //   };
    // }
    case types.SEARCH_ALL_USERS_SUCCESS:
    case types.ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
        loading: false,
        error: false,
      };
    }
    case types.LOAD_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          data: [...state.users.data, ...payload.data],
          meta: payload.meta,
          loading: false,
        },
        loading: false,
        error: false,
      };
    }
    case types.SEARCH_ALL_USERS_FAILURE:
    case types.ALL_USERS_FAILURE: {
      return {
        ...state,
        error: payload || true,
        loading: false,
      };
    }
    case types.CREATE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.CREATE_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          data: [...state.users.data, payload],
        },
        loading: false,
        error: false,
      };
    }
    case types.CREATE_USER_FAILURE: {
      return {
        ...state,
        error: payload || true,
        loading: false,
      };
    }
    case types.USER_INFO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.USER_INFO_SUCCESS: {
      return {
        ...state,
        currentUser: payload,
        loading: false,
        error: false,
      };
    }
    case types.USER_INFO_FAILURE: {
      return {
        ...state,
        error: payload || true,
        loading: false,
      };
    }
    case types.UPDATE_USER_INFO_SUCCESS: {
      // if this user is already in user list - update it
      const userToChangeIndex = state.users.data.findIndex(element => element.id === payload.id);

      return {
        ...state,
        currentUser: payload,
        error: false,
        users:
          userToChangeIndex !== -1
            ? {
                ...state.users,
                data: [
                  ...state.users.data.slice(0, userToChangeIndex),
                  payload,
                  ...state.users.data.slice(userToChangeIndex + 1),
                ],
              }
            : state.users,
      };
    }
    case types.UPDATE_USER_INFO_FAILURE: {
      return {
        ...state,
        error: payload || true,
      };
    }
    case types.REMOVE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.REMOVE_USER_SUCCESS: {
      return {
        ...state,
        currentUser: {},
        loading: false,
        error: false,
      };
    }
    case types.REMOVE_USER_FAILURE: {
      return {
        ...state,
        error: payload || true,
        loading: false,
      };
    }

    case types.DELETE_CARD_SUCCESS:
    case types.ADD_CARD_SUCCESS:
    case types.EDIT_CARD_SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          cards: payload,
        },
      };
    }

    case types.ADD_NOTES_REQUEST:
      return {
        ...state,
        loadingNoteAdd: true,
      };
    case types.DELETE_NOTES_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notes: state.currentUser.notes.filter(note => note.id !== payload),
        },
      };
    case types.EDIT_NOTES_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notes: state.currentUser.notes.filter(note => note.id !== payload.noteId),
        },
      };
    case types.ADD_NOTES_SUCCESS: {
      return {
        ...state,
        loadingNoteAdd: false,
        currentUser: {
          ...state.currentUser,
          notes: [...state.currentUser.notes, payload],
        },
      };
    }

    case typesComputer.START_SESSION_SUCCESS: {
      // if there is such user in current user list - update it, if no - add it
      const computerId = payload.id;
      const userId = payload.user.id;

      const userToChangeIndex = state.users.data.findIndex(element => element.id === userId);

      if (userToChangeIndex === -1) {
        return {
          ...state,
          users: {
            ...state.users,
            data: [...state.users.data, payload.user],
          },
        };
      }
      return {
        ...state,
        users: {
          ...state.users,
          data: [
            ...state.users.data.slice(0, userToChangeIndex),
            {
              ...state.users.data[userToChangeIndex],
              computer: computerId,
            },
            ...state.users.data.slice(userToChangeIndex + 1),
          ],
        },
      };
    }
    case typesComputer.TRANSFER_USER_SUCCESS: {
      // if there is such user in current user list - update it, if no - skip
      const { user, id } = payload.computer;

      const userToChangeIndex = state.users.data.findIndex(element => element.id === user.id);

      if (userToChangeIndex === -1) {
        return state;
      }

      return {
        ...state,
        users: {
          ...state.users,
          data: [
            ...state.users.data.slice(0, userToChangeIndex),
            {
              ...state.users.data[userToChangeIndex],
              computer: id,
            },
            ...state.users.data.slice(userToChangeIndex + 1),
          ],
        },
      };
    }

    case typesComputer.END_SESSION_SUCCESS: {
      // if there is such user in current user list - update it, if no - skip
      const { userId } = payload;

      const userToChangeIndex = state.users.data.findIndex(element => element.id === userId);

      if (userToChangeIndex === -1) {
        return state;
      }

      return {
        ...state,
        users: {
          ...state.users,
          data: [
            ...state.users.data.slice(0, userToChangeIndex),
            {
              ...state.users.data[userToChangeIndex],
              computer: null,
            },
            ...state.users.data.slice(userToChangeIndex + 1),
          ],
        },
      };
    }

    case typesComputer.WS_SESSION_FINISHED_SUCCESS: {
      // if there is such user in current user list - update it, if no - skip
      const { user } = payload;

      const userToChangeIndex = state.users.data.findIndex(element => element.id === user);

      if (userToChangeIndex === -1) {
        return state;
      }

      return {
        ...state,
        users: {
          ...state.users,
          data: [
            ...state.users.data.slice(0, userToChangeIndex),
            {
              ...state.users.data[userToChangeIndex],
              computer: null,
            },
            ...state.users.data.slice(userToChangeIndex + 1),
          ],
        },
      };
    }

    case typesComputer.WS_SESSION_STARTED_SUCCESS: {
      // if there is such user in current user list - update it, if no - add it
      const { user, computer } = payload;
      const userToChangeIndex = state.users.data.findIndex(element => element.id === user.id);

      if (userToChangeIndex === -1) {
        return {
          ...state,
          users: {
            ...state.users,
            data: [...state.users.data, payload.user],
          },
        };
      }

      return {
        ...state,
        users: {
          ...state.users,
          data: [
            ...state.users.data.slice(0, userToChangeIndex),
            {
              ...state.users.data[userToChangeIndex],
              computer,
            },
            ...state.users.data.slice(userToChangeIndex + 1),
          ],
        },
      };
    }
    case types.GET_INVOICE_SUCCESS: {
      return {
        ...state,
        invoice: payload,
        loading: false,
      };
    }
    case types.CLEAR_INVOICE_STATE: {
      return {
        ...state,
        invoice: {},
      };
    }
    case types.GET_INVOICE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.REFILL_INVOICE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.REFILL_INVOICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoice: payload,
      };
    }
    case types.REFILL_INVOICE_FAILURE: {
      return {
        ...state,
        error: payload || true,
        loading: false,
      };
    }
    case types.REFILLS_REQUEST:
    case types.REFILLS_SUCCESS: {
      return {
        ...state,
        refills: payload,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
