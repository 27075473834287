import { color } from '../theme';

const MuiTableRow = {
  hover: {
    '&:hover.MuiTableRow-root': {
      backgroundColor: color.primaryWhite,
    },
  },
};

export default MuiTableRow;
