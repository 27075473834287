/* eslint-disable import/no-mutable-exports */
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './middleware';
import rootReducer from './reducers';

const exampleInitialState = {};
export let store = null;

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export function configureStore(initialState = exampleInitialState) {
  const sagaMiddleware = createSagaMiddleware();
  // eslint-disable-next-line no-multi-assign
  const result = (store = createStore(rootReducer, initialState, bindMiddleware([sagaMiddleware])));
  result.sagaTask = sagaMiddleware.run(rootSaga);
  return result;
}
