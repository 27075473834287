export const fontPrimary = {
  proxima: ['proxima-regular', 'proxima-semibold', 'proxima-bold'],
};
// export const font = {
//   proximaSemibold: css`
//     font-family: ${fontPrimary.proxima[1]};
//   `,
//   proximaBold: css`
//     font-family: ${fontPrimary.proxima[2]};
//   `,
// };

export const fontSize = {
  h2: '30px',
  general: '14px',
  min: '12px',
};

export const color = {
  white: '#fff',
  primaryWhite: '#F6F7FF',
  blue: '#3066ED',
  primaryGrey: '#ACB6BF',
  lightBlue: '#7EA1F6',
  primaryBlue: '#1D4FCB',
  lightestBlue: '#E8EEFF',
  grey: '#AEB6BE',
  black: '#333232',
  primaryBlack: '#494949',
  red: '#FF6E65',
  green: '#20CB92',
  borderGrey: '#D5DDE3',
  computerOFF: '#C7D7FF',
};

export const transition = {
  general: '0.3s',
};

export const borderRadius = {
  general: '4px',
};

export const calcResponse = (fMin, fMax, vMin = 320, vMax = 1920) => {
  const k = ((fMax - fMin) / (vMax - vMin)).toFixed(2);
  let b = (fMin - k * vMin).toFixed(2);
  b += 'px';
  return `calc(${k} * 100vw + ${b})`;
};

export const trimExt = name =>
  name
    .split('.')
    .slice(0, -1)
    .join('.');

export const abbr = name =>
  name
    .split(' ')
    .map(x => x[0])
    .join('');
