import { color } from '../theme';

const MuiButtons = {
  root: {
    borderRadius: 2,
    fontWeight: 'bold',
    textTransform: 'unset',
    fontSize: 14,
    padding: '4px 15px',
    cursor: 'pointer',
    minWidth: 'auto',
  },
  contained: {
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none',
    },
  },
  outlined: {
    padding: '4px 15px',
  },
  textSecondary: {
    color: color.red,
  },
  // outlined: {
  // },
  sizeSmall: {
    fontSize: 12,
  },
  sizeLarge: {
    fontSize: '16px',
    borderRadius: 4,
  },
};

export default MuiButtons;
