import { MASK } from '../../../../config';
import Mask from '../../../_atoms/Mask';

const name = {
  name: 'name',
  label: 'Name',
  type: 'text',
};

const type = {
  name: 'type',
  label: 'Category',
  type: 'select',
  options: [
    {
      id: 'TIME',
      name: 'Time',
    },
    {
      id: 'ACCESSORIES',
      name: 'Accessories',
    },
    {
      id: 'PACKAGES',
      name: 'Packages',
    },
    {
      id: 'FAX_AND_COPY',
      name: 'Prints',
    },
  ],
};

const price = {
  name: 'price',
  label: 'Price',
  type: 'number',
  className: 'itemNumInput priceInput',
  placeholder: '0.00',
};

export const fieldsPrint = [
  name,
  type,
  {
    name: 'printerId',
    label: 'Printer',
    type: 'select',
    options: [],
  },
  price,
];

export const fieldsTime = [
  name,
  type,
  {
    name: 'hours',
    label: 'Hours',
    type: 'text',
    gridSize: 6,
    className: 'itemNumInput',
    placeholder: '00:00',
    inputComponent: Mask,
    inputProps: {
      mask: MASK.itemHours,
    },
  },
  {
    ...price,
    label: 'Regular price',
    gridSize: 6,
  },
  {
    name: 'promo',
    label: 'Promo',
    type: 'text',
    gridSize: 6,
    defaultValue: 0,
    className: 'itemNumInput',
    placeholder: '00:00',
    inputComponent: Mask,
    inputProps: {
      mask: MASK.itemHours,
    },
  },
  {
    name: 'discount',
    label: 'Discount',
    type: 'number',
    gridSize: 6,
    defaultValue: 0,
    className: 'itemNumInput priceInput',
    placeholder: '0.00',
  },
];

export const fields = [name, type, price];
