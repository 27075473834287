const nameToLabel = string => {
  const spacedString = string.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/_/g, ' ');

  const regex = /\b[A-Z]{2,}\b/g;

  const modified = spacedString.replace(regex, match => match.toLowerCase());

  return `${modified[0].toUpperCase()}${modified.slice(1)}`;
};

export default nameToLabel;
