import { Box, Grid, InputBase, InputLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';

import { fields, fieldsPrint, fieldsTime } from './fields';
import SelectField from './SelectField';

const Form = ({ formRef, onSubmit, closeDialog, initial = { type: 'PACKAGES' } }) => {
  const fieldsByType = {
    TIME: fieldsTime,
    PACKAGES: fields,
    FAX_AND_COPY: fieldsPrint,
    ACCESSORIES: fields,
  };

  const [fieldArray, setFieldArray] = useState(fieldsByType[initial.type]);

  const formik = useFormik({
    initialValues: initial,
    // validationSchema: validation[validate],
    onSubmit: values => {
      const valuesToSend = { ...values };
      valuesToSend.price = parseFloat(valuesToSend.price)
        ?.toFixed(2)
        .toString();
      if (valuesToSend.discount) {
        valuesToSend.discount = parseFloat(valuesToSend.discount)
          ?.toFixed(2)
          .toString();
      }
      // console.log('valuesToSend', valuesToSend)
      onSubmit({
        values: valuesToSend,
        callback: closeDialog,
      });
    },
  });

  const { errors, touched, values, handleChange, handleBlur, handleSubmit, initialValues } = formik;

  return (
    <form ref={formRef} onSubmit={handleSubmit} handleSubmit={handleSubmit} style={{ width: 290 }}>
      <Grid container spacing={2}>
        {fieldArray.map(field => {
          if (field.type === 'select') {
            return (
              <Grid item xs={field.gridSize || 12} key={field.name}>
                <Box mb={1}>
                  <SelectField
                    onChange={e => {
                      handleChange(e);
                      if (field.name === 'type') {
                        setFieldArray(fieldsByType[e.target.value]);
                      }
                    }}
                    field={field}
                    initialValues={initialValues}
                    handleBlur={handleBlur}
                    values={values}
                  />
                </Box>
              </Grid>
            );
          }
          return (
            <Grid item xs={field.gridSize || 12} key={field.name}>
              <Box mb={1}>
                <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
                <InputBase
                  id={field.name}
                  fullWidth
                  type={field.type}
                  name={field.name}
                  value={values[field.name]}
                  error={errors[field.name] && touched[field.name]}
                  inputComponent={field.inputComponent}
                  inputProps={{
                    onChange: handleChange,
                    onBlur: handleBlur,
                    ...field.inputProps,
                  }}
                  className={field.className}
                  placeholder={field.placeholder}
                  disabled={
                    values.type === 'TIME' && field.name === 'hours' && !values.isHoursEditable
                  }
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </form>
  );
};

export default Form;
