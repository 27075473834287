/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { paths } from '../../config';
import DashboardPage from '../DashboardPage';
import EmployeesPage from '../EmployeesPage';
import PosPage from '../PosPage';
import Sales from '../Sales';
import ChatPage from '../ChatPage';
import PricesPage from '../PricesPage';
import NotificationsPage from '../NotificationsPage';

const Layout = lazy(() => import('../../app/layout/components'));

const privateRoutes = [
  {
    exact: true,
    path: paths.dashboard,
    component: () => (
      <Layout>
        <DashboardPage />
      </Layout>
    ),
  },
  {
    exact: true,
    path: paths.chat,
    component: () => (
      <Layout>
        <ChatPage />
      </Layout>
    ),
  },
  {
    exact: true,
    path: paths.employees,
    component: () => (
      <Layout>
        <EmployeesPage />
      </Layout>
    ),
  },
  {
    exact: true,
    path: paths.pos,
    component: () => (
      <Layout>
        <PosPage />
      </Layout>
    ),
  },
  {
    exact: true,
    path: paths.sales,
    component: () => (
      <Layout>
        <Sales />
      </Layout>
    ),
  },
  {
    exact: true,
    path: paths.prices,
    component: () => (
      <Layout>
        <PricesPage />
      </Layout>
    ),
  },
  {
    exact: true,
    path: paths.notifications,
    component: () => (
      <Layout>
        <NotificationsPage />
      </Layout>
    ),
  },
];

export default privateRoutes;
