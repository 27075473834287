import axios from 'axios';
import { removeToken } from '../app/auth/state/actions';
import { clearToken } from '../utils/auth';
import { paths } from '../config';

export default {
  setupInterceptors: (store, history) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        const { status } = error.response;

        if (status === 401) {
          clearToken();
          store.dispatch(removeToken());
          history.push(paths.signin);
        }

        return Promise.reject(error);
      },
    );
  },
};
