import React from 'react';

import Wrapper from '../app/_common/Wrapper';
import ItemsContainer from '../app/items/containers/ItemsContainer';
import PrintersProvider from '../providers/PrintersProvider';

const PricesPage = () => {
  return (
    <Wrapper>
      <PrintersProvider>
        <ItemsContainer />
      </PrintersProvider>
    </Wrapper>
  );
};
export default PricesPage;
