import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import ActionButtons from '../ActionButtons';

const TableRowPrints = ({ row, cellProps }) => (
  <TableRow key={row.id}>
    <TableCell {...cellProps}>{row.name}</TableCell>
    <TableCell component="th" scope="row" {...cellProps}>
      {row.printer}
    </TableCell>
    <TableCell component="th" scope="row" {...cellProps}>
      {row.price}
    </TableCell>
    <TableCell align="right">
      <ActionButtons item={row} />
    </TableCell>
  </TableRow>
);

export default TableRowPrints;
