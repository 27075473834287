import * as types from './types';

export const AllUsersRequest = payload => {
  return {
    type: types.ALL_USERS_REQUEST,
    payload,
  };
};

export const AllUsersSuccess = payload => {
  return {
    type: types.ALL_USERS_SUCCESS,
    payload,
  };
};

export const AllUsersFailure = payload => {
  return {
    type: types.ALL_USERS_FAILURE,
    payload,
  };
};

export const SearchAllUsersRequest = payload => {
  return {
    type: types.SEARCH_ALL_USERS_REQUEST,
    payload,
  };
};

export const LoadAllUsersSuccess = payload => {
  return {
    type: types.LOAD_ALL_USERS_SUCCESS,
    payload,
  };
};

export const SearchAllUsersSuccess = payload => {
  return {
    type: types.SEARCH_ALL_USERS_SUCCESS,
    payload,
  };
};

export const SearchAllUsersFailure = payload => {
  return {
    type: types.SEARCH_ALL_USERS_FAILURE,
    payload,
  };
};

export const CreateUserRequest = payload => {
  return {
    type: types.CREATE_USER_REQUEST,
    payload,
  };
};

export const CreateUserSuccess = payload => {
  return {
    type: types.CREATE_USER_SUCCESS,
    payload,
  };
};

export const CreateUserFailure = payload => {
  return {
    type: types.CREATE_USER_FAILURE,
    payload,
  };
};

export const UserInfoRequest = payload => {
  return {
    type: types.USER_INFO_REQUEST,
    payload,
  };
};

export const UserInfoSuccess = payload => {
  return {
    type: types.USER_INFO_SUCCESS,
    payload,
  };
};

export const UserInfoFailure = payload => {
  return {
    type: types.USER_INFO_FAILURE,
    payload,
  };
};

export const UpdateUserInfoRequest = payload => {
  return {
    type: types.UPDATE_USER_INFO_REQUEST,
    payload,
  };
};

export const UpdateUserInfoSuccess = payload => {
  return {
    type: types.UPDATE_USER_INFO_SUCCESS,
    payload,
  };
};

export const UpdateUserInfoFailure = payload => {
  return {
    type: types.UPDATE_USER_INFO_FAILURE,
    payload,
  };
};

export const RemoveUserRequest = payload => {
  return {
    type: types.REMOVE_USER_REQUEST,
    payload,
  };
};

export const RemoveUserSuccess = payload => {
  return {
    type: types.REMOVE_USER_SUCCESS,
    payload,
  };
};

export const RemoveUserFailure = payload => {
  return {
    type: types.REMOVE_USER_FAILURE,
    payload,
  };
};

export const addCardRequest = payload => ({
  type: types.ADD_CARD_REQUEST,
  payload,
});

export const addCardSuccess = payload => ({
  type: types.ADD_CARD_SUCCESS,
  payload,
});

export const addCardFailure = payload => ({
  type: types.ADD_CARD_FAILURE,
  payload,
});

export const editCardRequest = payload => ({
  type: types.EDIT_CARD_REQUEST,
  payload,
});

export const editCardSuccess = payload => ({
  type: types.EDIT_CARD_SUCCESS,
  payload,
});

export const editCardFailure = payload => ({
  type: types.EDIT_CARD_FAILURE,
  payload,
});

export const deleteCardRequest = payload => ({
  type: types.DELETE_CARD_REQUEST,
  payload,
});

export const deleteCardSuccess = payload => ({
  type: types.DELETE_CARD_SUCCESS,
  payload,
});

export const deleteCardFailure = payload => ({
  type: types.DELETE_CARD_FAILURE,
  payload,
});

export const addNotesRequest = payload => ({
  type: types.ADD_NOTES_REQUEST,
  payload,
});

export const addNotesSuccess = payload => ({
  type: types.ADD_NOTES_SUCCESS,
  payload,
});

export const addNotesFailure = payload => ({
  type: types.ADD_NOTES_FAILURE,
  payload,
});

export const editNotesRequest = payload => ({
  type: types.EDIT_NOTES_REQUEST,
  payload,
});

export const editNotesSuccess = payload => ({
  type: types.EDIT_NOTES_SUCCESS,
  payload,
});

export const editNotesFailure = payload => ({
  type: types.EDIT_NOTES_FAILURE,
  payload,
});

export const deleteNotesRequest = payload => ({
  type: types.DELETE_NOTES_REQUEST,
  payload,
});

export const deleteNotesSuccess = payload => ({
  type: types.DELETE_NOTES_SUCCESS,
  payload,
});

export const deleteNotesFailure = payload => ({
  type: types.DELETE_NOTES_FAILURE,
  payload,
});

// maybe we should delete refillInvoiceRequest and refillInvoiceSuccess
export const refillInvoiceRequest = payload => {
  return {
    type: types.REFILL_INVOICE_REQUEST,
    payload,
  };
};

export const refillInvoiceSuccess = payload => {
  return {
    type: types.REFILL_INVOICE_SUCCESS,
    payload,
  };
};

export const getInvoiceRequest = payload => ({
  type: types.GET_INVOICE_REQUEST,
  payload,
});

export const getInvoiceSuccess = payload => ({
  type: types.GET_INVOICE_SUCCESS,
  payload,
});

export const clearInvoiceState = payload => ({
  type: types.CLEAR_INVOICE_STATE,
  payload,
});

export const checkoutRequest = payload => ({
  type: types.CHECKOUT_REQUEST,
  payload,
});

export const checkoutSuccess = payload => ({
  type: types.CHECKOUT_SUCCESS,
  payload,
});

export const RefillsRequest = payload => ({
  type: types.REFILLS_REQUEST,
  payload,
});

export const RefillsSuccess = payload => ({
  type: types.REFILLS_SUCCESS,
  payload,
});
