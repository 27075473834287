import React from 'react';
import { makeStyles } from '@material-ui/core';
import SvgSymbol from '../../_atoms/InlineSvg';
import ButtonWithOptions from '../../_common/ButtonWithOptions';

const useStyles = makeStyles(theme => ({
  btnOption: {
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.lightestBlue,
    },
  },
}));
const OptionsContainer = () => {
  const classes = useStyles();
  const actionItems = [
    {
      text: 'Chat to All PCs',
      onClick: ({ callback }) => callback(),
      dialog: {
        title: `Chat to All PCs?`,
      },
    },
    {
      text: "Lock all PC's",
      onClick: ({ callback }) => callback(),
      dialog: {
        title: `Lock all PC's?`,
      },
    },
    {
      text: 'Unlock all PCs',
      onClick: ({ callback }) => callback(),
      dialog: {
        title: `Unlock all PC's?`,
      },
    },
    {
      text: 'Set to admin All PCs',
      onClick: ({ callback }) => callback(),
      dialog: {
        title: `Set to admin All PCs?`,
      },
    },
    {
      text: 'Shutdown All PCs',
      onClick: ({ callback }) => callback(),
      dialog: {
        title: `Shutdown All PCs?`,
      },
    },
    {
      text: 'Shutdown unused PCs',
      onClick: ({ callback }) => callback(),
      dialog: {
        title: `Shutdown unused PCs?`,
      },
    },
    {
      text: 'End All Current sessions',
      onClick: ({ callback }) => callback(),
      dialog: {
        title: `End All Current sessions?`,
      },
    },
  ];

  return (
    <ButtonWithOptions
      buttonProps={{
        color: 'primary',
        endIcon: <SvgSymbol iconName="dots" width="16" height="16" fill="primary" />,
        'aria-controls': 'options',
        classes: {
          root: classes.btnOption,
        },
      }}
      actionItems={actionItems}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: -40,
        horizontal: 'right',
      }}
    >
      Options
    </ButtonWithOptions>
  );
};

export default OptionsContainer;
