import * as types from './types';

export const getNotificationsRequest = payload => ({
  type: types.GET_NOTIFICATIONS_REQUEST,
  payload,
});

export const getNotificationsSuccess = payload => ({
  type: types.GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const editNotificationRequest = payload => ({
  type: types.EDIT_NOTIFICATION_REQUEST,
  payload,
});

export const editNotificationSuccess = payload => ({
  type: types.EDIT_NOTIFICATION_SUCCESS,
  payload,
});
