export const PROJECT_NAME = 'TheQue';

export const BASE_URI = process.env.REACT_APP_BASE_API_URI;

export const BASE_API_URI = `${BASE_URI}/api/`;

export const SOCKET_PATH = '/socket.io';

export const SOCKET_URIS = {
  sessions: '/sessions',
};

const auth = {
  login: 'auth/login',
  logout: 'auth/logout',
  signup: 'auth/signup',
  me: 'auth/me',
};

const users = {
  users: 'users',
  notes: 'notes',
  cards: 'cards',
};

const computers = {
  computers: 'computers',
};

const sessions = {
  sessions: 'sessions/',
  users: 'users/',
  finish: 'finish',
};

const invoices = {
  invoices: 'invoices',
  payments: '/payments',
};

const items = {
  items: 'items',
};

const notifications = {
  notifications: 'notifications',
};

const printers = {
  printers: 'printers',
};

const refills = {
  refills: 'refills',
};

export const API_URIS = {
  auth,
  users,
  computers,
  sessions,
  invoices,
  items,
  notifications,
  printers,
  refills,
};

export const userRoute = 'userRoute';
export const userQuery = 'userQuery';

export const paths = {
  signin: '/',
  dashboard: '/dashboard',
  chat: '/chat',
  pos: '/pos',
  sales: '/sales',
  prices: '/prices',
  employees: '/employees',
  notifications: '/notifications',
  refill: '/refill',
  checkout: '/checkout',
};

export const MASK = {
  cardNumber: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  expMonth: [/\d/, /\d/],
  // expMonth: [/[01]/, /\d/],
  expYear: [/\d/, /\d/, /\d/, /\d/],
  cvc: [/\d/, /\d/, /\d/, /\d/],
  phone: ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  itemHours: [/\d/, /\d/, ':', /\d/, /\d/],
};

export const autoRefillOptions = [3600, 7200, 10800];
