import { InputBase, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';

import PrintersContext from '../../../../providers/PrintersProvider/PrintersContext';
import { palette } from '../../../../styles/palette';
import SvgSymbol from '../../../_atoms/InlineSvg';

const useStyles = makeStyles(theme => ({
  select: {
    backgroundColor: theme.palette.primary.lightestBlue,
  },
}));

const SelectField = ({ field, initialValues, onChange, handleBlur, values }) => {
  const classes = useStyles();

  const { printers } = useContext(PrintersContext);

  const options = field.name === 'printerId' ? Object.values(printers) : field.options;

  return (
    <>
      <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
      <Select
        disableUnderline
        fullWidth
        name={field.name}
        IconComponent={() => <SvgSymbol iconName="arrow" className="arrow" />}
        defaultValue={initialValues[field.name]}
        onChange={onChange}
        // disable type selection if we edit existing item
        disabled={field.name === 'type' && initialValues.id}
        onBlur={handleBlur}
        style={{
          height: 44,
          color: palette.primary.main,
          fontWeight: 600,
        }}
        input={<InputBase classes={{ root: values[field.name] && classes.select }} />}
      >
        {options.map(option => (
          <MenuItem value={option.id} key={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SelectField;
