const MuiSelect = {
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&$select': {
      padding: '0 20px 0 10px',
      minWidth: 68,
    },
  },
};

export default MuiSelect;
