import Box from '@material-ui/core/Box';
import React from 'react';

import Wrapper from '../app/_common/Wrapper';
import ComputersContainer from '../app/computers/containers/ComputersContainer';
import OptionsContainer from '../app/computers/containers/OptionsContainer';

const DashboardPage = () => {
  return (
    <Wrapper>
      <Box textAlign="right">
        <OptionsContainer />
      </Box>
      <ComputersContainer />
    </Wrapper>
  );
};
export default DashboardPage;
