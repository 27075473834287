import { Typography } from '@material-ui/core';
import React from 'react';
import cropText from '../../../utils/cropText';

const UserName = ({ user }) => (
  <>
    <Typography component="span" style={{ fontWeight: 'bold' }} variant="body1">
      {cropText(`${user?.firstName} ${user?.lastName}`, 17)}
    </Typography>
    <Typography
      color="textSecondary"
      component="span"
      style={{ fontWeight: 'bold' }}
      variant="body1"
    >
      {` (${user?.gender?.[0]})`}
    </Typography>
  </>
);

export default UserName;
