import * as types from './types';

const initialState = {
  data: {},
  meta: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.ALL_INVOICES_SUCCESS: {
      return { ...state, data: { ...state.data, ...payload.data }, meta: payload.meta };
    }
    case types.ALL_INVOICES_RESET: {
      return { ...state, data: initialState.data, meta: initialState.meta };
    }

    case types.DELETE_INVOICES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
