// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';
import { PROJECT_NAME } from '../config';

export const getToken = () => Cookies.get(PROJECT_NAME);
export const setToken = token => {
  Cookies.set(PROJECT_NAME, token);
};

export const clearToken = () => Cookies.remove(PROJECT_NAME);
