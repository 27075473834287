import { IconButton, makeStyles, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import getLocalDate from '../../../../utils/getLocalDate';
import nameToLabel from '../../../../utils/nameToLabel';
import Sprite from '../../../../assets/images/sprite.svg';
import { TypeInvoice } from '../../../../types';
import SvgSymbol from '../../../_atoms/InlineSvg';
import Details from './Details';

const useStyles = makeStyles(theme => ({
  row: ({ gridTemplateColumns }) => ({
    display: 'grid',
    gridTemplateColumns,
    justifyContent: 'unset',
    border: `1px solid ${theme.palette.borders.main}`,
  }),
  cell: ({ isExpanded }) => ({
    color: isExpanded ? theme.palette.primary.main : theme.palette.grey.A700,
    fontSize: 12,
    fontWeight: 600,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.common.white,
  }),
  buttonTd: {
    padding: `${theme.spacing(0.5)}px 0 !important`,
  },
  arrow: ({ isExpanded }) => ({
    fill: isExpanded ? theme.palette.primary.main : theme.palette.primary.lightBlue,
    '&:hover': {
      fill: theme.palette.primary.main,
    },
    transition: `transform ${theme.transitions.duration.shortest}ms`,
    transform: isExpanded ? 'rotateZ(180deg)' : 'rotateZ(0deg)',
  }),
  chip: {
    height: 16,
    fontSize: 10,
    marginLeft: 8,
    '& span': {
      paddingLeft: 5,
      paddingRight: 5,
      textTransform: 'lowercase',
      fontWeight: 'normal',
    },
  },
}));

const InvoiceRow = ({ invoice, gridTemplateColumns, deleteInvoice }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const toggleExpanded = () => {
    if (!isExpanded) {
      setShowConfirm(false);
    }
    setExpanded(!isExpanded);
  };

  const classes = useStyles({ gridTemplateColumns, isExpanded });

  const type =
    invoice.invoiceToItems.length === 1 && invoice.invoiceToItems[0].amount === 1
      ? 'Item'
      : 'Order';

  const cancel = () => {
    deleteInvoice(invoice.id, () => setExpanded(false));
  };

  const cells = [
    getLocalDate(invoice.createdAt, 'DD/MM/YYYY'),
    getLocalDate(invoice.createdAt, 'h:m:s A'),
    invoice.id,
    invoice.client,
    <div key={invoice.id}>
      {type}
      {invoice.state && (
        <Chip
          size="small"
          label={nameToLabel(invoice.state)}
          className={classes.chip}
          variant="outlined"
        />
      )}
    </div>,
    `$${parseFloat(invoice.total > 0 ? invoice.total : 0).toFixed(2)}`,
  ];

  return (
    <>
      <div
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.row}
        classes={{
          content: classes.row,
          root: classes.MuiExpansionPanelSummary,
        }}
      >
        {cells.map((cell, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className={classes.cell}>
            {cell || '--'}
          </div>
        ))}
        <div className={`${classes.cell} ${classes.buttonTd}`}>
          <IconButton size="small" onClick={toggleExpanded}>
            <SvgSymbol
              className={classes.arrow}
              path={Sprite}
              iconName="arrow"
              width={18}
              height={18}
            />
          </IconButton>
        </div>
      </div>
      <Details
        isExpanded={isExpanded}
        gridTemplateColumns={gridTemplateColumns}
        invoice={invoice}
        setShowConfirm={setShowConfirm}
        cancel={cancel}
        showConfirm={showConfirm}
      />
    </>
  );
};

InvoiceRow.propTypes = {
  invoice: TypeInvoice.isRequired,
  gridTemplateColumns: PropTypes.string.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
};

export default InvoiceRow;
