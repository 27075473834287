import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as api from '../api';
import * as types from './types';
import * as actions from './actions';
import getStringFromError from '../../../utils/getStringFromError';

function* ProfileRequest() {
  try {
    const { data } = yield call(api.getUser);
    yield put(actions.ProfileSuccess(data));
  } catch (err) {
    console.error('ProfileFailure', err);
    const errorMessage = getStringFromError(err);
    yield put(actions.ProfileFailure(errorMessage));
  }
}

export default function* profileSaga() {
  yield all([takeEvery(types.PROFILE_REQUEST, ProfileRequest)]);
}
