import { Button, Grid, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cropTimeString from '../../../../utils/cropTimeString';
import SvgSymbol from '../../../_atoms/InlineSvg';
import UserName from '../../../_common/UserName';
import * as actions from '../../state/actions';
import * as selectors from '../../state/selectors';

const useStyles = makeStyles(theme => ({
  selectedContainer: {
    border: `1px solid ${theme.palette.borders.light}`,
    width: 325,
    height: 32,
  },
  input: {
    height: 32,
    '& .MuiInputBase-root': {
      marginTop: 0,
      paddingLeft: 4,
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.borders.light}`,
      },
    },
    '& .MuiInput-underline:after': {
      border: `1px solid ${theme.palette.borders.light}`,
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.borders.light}`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${theme.palette.borders.light}`,
    },
    '& input': {
      height: 18,
    },
  },
  optionContainer: {
    display: 'grid',
    gridTemplateColumns: '140px 100px 25px',
    width: '100%',
    paddingLeft: 8,
  },
  option: {
    paddingLeft: 4,
    paddingRight: 4,
    borderBottom: `1px solid ${theme.palette.borders.main}`,
  },
}));

const Option = ({ user }) => {
  const classes = useStyles();

  return (
    <div className={classes.optionContainer}>
      <div>
        <UserName user={user} />
      </div>
      <Typography color="textSecondary">{user.phone}</Typography>
      <Typography color="textSecondary">{cropTimeString(user.time)}</Typography>
    </div>
  );
};

const SearchUsers = ({ onStart }) => {
  const classes = useStyles();
  const data = useSelector(selectors.users);

  const dispatch = useDispatch();

  const searchUser = (e, val) =>
    dispatch(actions.getUsersRequest({ search: val, take: 10, status: 'INACTIVE' }));

  const unmountCleanup = () => {
    // need to clean reducer for next popup was clear
    dispatch(actions.cleanComputerUsersSearchList());
  };

  useEffect(() => {
    return unmountCleanup;
  }, []);

  const [value, setValue] = useState();

  const handleSelect = (e, val) => {
    if (e.keyCode === 13 && typeof val !== 'object') {
      e.preventDefault();
    } else {
      setValue(val);
    }
  };

  const handleStart = () => {
    onStart(value);
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        {value ? (
          <Grid container className={classes.selectedContainer} alignItems="center">
            <Grid item xs={11}>
              <Option user={value} />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => setValue(null)} size="small">
                <SvgSymbol iconName="close" width={15} height={15} fill="#C7D7FF" />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Autocomplete
            id="combo-box-demo"
            // options={data}
            options={data.filter(user => !user.computer)}
            // getOptionLabel={option => <Option user={option} />}
            getOptionLabel={option => option.firstName}
            renderOption={option => <Option user={option} />}
            style={{ width: 300, height: 32 }}
            className={classes.input}
            renderInput={params => <TextField {...params} />}
            freeSolo
            onInputChange={searchUser}
            onChange={handleSelect}
            classes={{
              option: classes.option,
            }}
          />
        )}
      </Grid>
      <Grid item>
        <Button
          onClick={handleStart}
          variant="contained"
          color="primary"
          style={{ height: '100%', width: 88 }}
          disabled={!value}
        >
          Start
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchUsers;
