import * as types from './types';
import * as userTypes from '../../users/state/types';

const initialState = {
  data: {},
  users: [],
  loading: false,
  isArrangeMode: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_PC_REQUEST: {
      return { ...state, loading: true };
    }

    case types.GET_ALL_PC_SUCCESS: {
      return { ...state, data: payload.data, loading: false };
    }

    case types.GET_USERS_PC_SUCCESS: {
      return { ...state, users: payload };
    }

    case types.EDIT_PC_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    }

    case types.CLEAN_PC_USERS_SEARCH_LIST: {
      return {
        ...state,
        users: initialState.users,
      };
    }

    case types.START_SESSION_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
      };
    }

    case types.WS_SESSION_FINISHED_SUCCESS:
    case types.WS_SESSION_STARTED_SUCCESS:
    case types.WS_STATUS_CHANGED_SUCCESS:
    case types.END_SESSION_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.computer.id]: payload.computer,
        },
      };
    }

    case types.TRANSFER_USER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.computer.id]: payload.computer,
          [payload.oldComputer]: {
            ...state.data[payload.oldComputer],
            user: null,
            status: 'ON',
          },
        },
      };
    }

    case types.ARRANGE_PC_MODE: {
      return {
        ...state,
        isArrangeMode: payload,
      };
    }
    case types.ARRANGE_PC_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
      };
    }

    case userTypes.UPDATE_USER_INFO_SUCCESS: {
      if (payload.computer) {
        const computerToChange = Object.values(state.data).find(
          computer => computer.user?.id === payload.id,
        );
        if (computerToChange) {
          return {
            ...state,
            data: {
              ...state.data,
              [computerToChange.id]: {
                ...computerToChange,
                user: payload,
              },
            },
          };
        }
      }
      return state;
    }

    default:
      return state;
  }
};

export default reducer;
