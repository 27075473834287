import * as types from './types';

export const removeToken = () => ({
  type: types.REMOVE_TOKEN,
});

export const LoginRequest = payload => {
  return {
    type: types.LOGIN_REQUEST,
    payload,
  };
};

export const LoginSuccess = payload => {
  return {
    type: types.LOGIN_SUCCESS,
    payload,
  };
};

export const LoginFailure = payload => {
  return {
    type: types.LOGIN_FAILURE,
    payload,
  };
};

export const LogoutRequest = payload => {
  return {
    type: types.LOGOUT_REQUEST,
    payload,
  };
};

export const LogoutSuccess = payload => {
  return {
    type: types.LOGOUT_SUCCESS,
    payload,
  };
};

export const LogoutFailure = payload => {
  return {
    type: types.LOGOUT_FAILURE,
    payload,
  };
};
