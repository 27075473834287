import React from 'react';
import Wrapper from '../app/_common/Wrapper';
import InvoicesContainer from '../app/invoices/containers/InvoicesContainer';

const InvoicesPage = () => (
  <Wrapper>
    <InvoicesContainer />
  </Wrapper>
);
export default InvoicesPage;
