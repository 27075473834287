/* eslint-disable */
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';

import { palette } from '../../../../styles/palette';
import SearchUsers from './SearchUsers';
import Transfer from './Transfer';
import SvgSymbol from '../../../_atoms/InlineSvg';
import ButtonWithOptions from '../../../_common/ButtonWithOptions';

const useStyles = makeStyles(theme => ({
  chatIcon: {
    fill: theme.palette.primary.off,
  },
  btnIcon: {
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.lightestBlue,
    },
  },
}));

const ActionIcons = ({
  pc,
  onStartSession,
  endSession,
  transferUser,
  editMode,
  inUse,
  hasUser,
  onHandlePcEdit,
}) => {
  const classes = useStyles({ inUse, editMode });

  const actionItems = [
    {
      text: `${pc.available === 'LOCKED' ? 'Unlock' : 'Lock'} PC`,
      onClick: ({ callback }) =>
        onHandlePcEdit({
          values: {
            available: `${pc.available === 'LOCKED' ? 'UNLOCKED' : 'LOCKED'}`,
          },
          compId: pc.id,
          successMessage: `${pc.name} is ${pc.available === 'LOCKED' ? 'unlocked' : 'locked'}`,
          callback,
        }),
      dialog: {
        title: `Are you sure you want to ${pc.available === 'LOCKED' ? 'unlock' : 'lock'} PC`,
      },
    },
    {
      text: `${!pc.isAdminAccess ? 'Set to' : 'Cancel'} admin access`,
      onClick: ({ callback }) =>
        onHandlePcEdit({
          values: {
            isAdminAccess: !pc.isAdminAccess,
          },
          compId: pc?.id,
          successMessage: `${pc.name} ${!pc.isAdminAccess ? 'Set to' : 'Cancel'} admin access`,
          callback,
        }),
      dialog: {
        title: `Are you sure you want to ${!pc.isAdminAccess ? 'set to' : 'cancel'} admin access?`,
      },
    },
    {
      text: 'Shutdown',
      onClick: ({ callback }) =>
        onHandlePcEdit({
          values: {
            status: 'OFF',
          },
          compId: pc.id,
          successMessage: `${pc.name} changed status to OFF.`,
          callback,
        }),
      dialog: {
        title: `Are you sure you want to shutdown?`,
      },
    },
    {
      text: 'Start session',
      // onClick: () => null,
      dialog: {
        title: (
          <div>
            Start session: <span style={{ color: palette.primary.green }}>{` ${pc.name}`}</span>
          </div>
        ),
        content: ({ closeDialog }) => (
          <SearchUsers
            onStart={value => onStartSession({ value: value.id, callback: closeDialog })}
          />
        ),
        noActions: true,
      },
    },
  ];

  const actionItemsInUse = [
    {
      text: 'End current session',
      onClick: async ({ callback }) => {
        if (pc.available === 'LOCKING') {
          await onHandlePcEdit({
            values: {
              available: 'UNLOCKED',
            },
            compId: pc.id,
            successMessage: `${pc.name} is unlocked`,
            callback,
          });
        }
        await endSession({ computerId: pc.id, userId: pc?.user?.id, callback });
        if (pc.available === 'LOCKING') {
          await onHandlePcEdit({
            values: {
              available: 'LOCKED',
            },
            compId: pc.id,
            successMessage: `${pc.name} is locked`,
            callback,
          });
        }
      },
      dialog: {
        title: `Are you sure you want to end current session?`,
      },
    },
    {
      text: `${pc.available === 'LOCKING' ? 'Unlock' : 'Lock'} at end of session`,
      onClick: ({ callback }) =>
        onHandlePcEdit({
          values: {
            available: `${pc.available === 'LOCKING' ? 'UNLOCKED' : 'LOCKING'}`,
          },
          compId: pc.id,
          successMessage: `${pc.name} is ${pc.available === 'LOCKING' ? 'unlocked' : 'locked'}`,
          callback,
        }),
      dialog: {
        title: `Are you sure you want to ${
          pc.available === 'LOCKING' ? 'unlock' : 'lock'
        } at end of session?`,
      },
    },
    {
      text: `${!pc.isAdminAccess ? 'Set' : 'Cancel'} to admin access`,
      onClick: ({ callback }) =>
        onHandlePcEdit({
          values: {
            isAdminAccess: !pc.isAdminAccess,
          },
          compId: pc?.id,
          successMessage: `${pc.name} ${!pc.isAdminAccess ? 'Set' : 'Cancel'} to admin access`,
          callback,
        }),
      dialog: {
        title: `Are you sure you want to ${!pc.isAdminAccess ? 'set' : 'cancel'} to admin access?`,
      },
    },
    {
      text: 'Shutdown',
      onClick: async ({ callback }) => {
        if (pc.available === 'LOCKING') {
          await onHandlePcEdit({
            values: {
              available: 'UNLOCKED',
            },
            compId: pc.id,
            successMessage: `${pc.name} is unlocked`,
            callback,
          });
        }
        await onHandlePcEdit({
          values: {
            status: 'OFF',
          },
          compId: pc.id,
          successMessage: `${pc.name} changed status to OFF.`,
          callback,
        });
        if (pc.available === 'LOCKING') {
          await onHandlePcEdit({
            values: {
              available: 'LOCKED',
            },
            compId: pc.id,
            successMessage: `${pc.name} is locked`,
            callback,
          });
        }
      },
      dialog: {
        title: `Are you sure you want to shutdown?`,
      },
    },
    {
      text: 'Transfer to...',
      // onClick: () => null,
      Component: ({ closeMenu }) => (
        <Transfer
          closeMenu={closeMenu}
          transferUser={transferUser}
          computerId={pc.id}
          user={pc?.user}
        />
      ),
    },
  ];

  return (
    <Grid container justify="space-between" style={{ height: 22 }}>
      {!editMode && (
        <>
          <IconButton size="small" disabled={inUse !== 'IN_USE'}>
            <SvgSymbol iconName="chat" width="15" height="15" className={classes.chatIcon} />
          </IconButton>
          <ButtonWithOptions
            buttonProps={{
              color: 'primary',
              size: 'small',
              'aria-controls': 'computer',
              classes: {
                root: classes.btnIcon,
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              horizontal: 30,
              vertical: -5,
            }}
            icon
            actionItems={hasUser ? actionItemsInUse : actionItems}
          >
            <SvgSymbol iconName="dots" width="16" height="16" fill={palette.primary.lightBlue} />
          </ButtonWithOptions>
        </>
      )}
    </Grid>
  );
};

export default ActionIcons;
