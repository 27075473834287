import { all, put, call, select, debounce, takeEvery } from 'redux-saga/effects';
import keyBy from 'lodash.keyby';
import getStringFromError from '../../../utils/getStringFromError';

import { objToQueryString } from '../../../utils/query';
import showNotification from '../../../utils/showNotification';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import * as api from '../api';

function* allInvoicesRequest({ payload }) {
  try {
    const skip = yield select(selectors.invoices);

    const query = `?${objToQueryString({ ...payload, skip: Object.values(skip.data).length })}`;

    const { data, ...rest } = yield call(api.invoices, query);

    const dataById = keyBy(data, 'id');

    yield put(
      actions.allInvoicesSuccess({ data: dataById, meta: { 'x-total': rest.headers['x-total'] } }),
    );
  } catch (err) {
    console.error('allInvoicesRequest', err);
  }
}

function* deleteInvoiceRequest({ payload }) {
  try {
    const { data } = yield call(api.deleteInvoice, payload.id);

    yield put(showNotification(`Invoice #${payload.id} cancelled.`, 'success'));

    yield put(actions.deleteInvoiceSuccess(data));

    if (payload.callback) {
      payload.callback();
    }
  } catch (err) {
    console.error('deleteInvoiceRequest', err);

    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

export default function* authSaga() {
  yield all([
    debounce(200, types.ALL_INVOICES_REQUEST, allInvoicesRequest),
    takeEvery(types.DELETE_INVOICES_REQUEST, deleteInvoiceRequest),
  ]);
}
