import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { color } from '../../../styles/theme';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: `${color.primaryWhite}`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    maxHeight: '100vh',
    overflowY: 'auto',
  },
}));

const Wrapper = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.wrapper}>{children}</div>;
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Wrapper;
