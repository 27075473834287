import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { CssBaseline, Typography } from '@material-ui/core';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import App from './app/app';
import SocketInitializer from './app/computers/containers/SocketInitializer';
import { configureStore } from './store';
import MuiTheme from './styles/MuiTheme';
import NotificationProvider from './providers/NotificationProvider';
import Notifier from './app/layout/components/Notifier';
import httpService from './api/interceptor';

const store = configureStore();

const history = createBrowserHistory();
httpService.setupInterceptors(store, history);

render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={MuiTheme}>
        <CssBaseline />
        <Typography component="div">
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <NotificationProvider>
              <Notifier />
              <SocketInitializer />
              <App />
            </NotificationProvider>
          </MuiPickersUtilsProvider>
        </Typography>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
