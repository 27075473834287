import * as types from './types';

export const ProfileRequest = payload => {
  return {
    type: types.PROFILE_REQUEST,
    payload,
  };
};

export const ProfileSuccess = payload => {
  return {
    type: types.PROFILE_SUCCESS,
    payload,
  };
};

export const ProfileFailure = payload => {
  return {
    type: types.PROFILE_FAILURE,
    payload,
  };
};
