import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import { useSelector } from 'react-redux';

import arrow from '../../../../assets/images/icons/arrow.svg';
import { palette } from '../../../../styles/palette';
import ButtonWithOptions from '../../../_common/ButtonWithOptions';
import UserName from '../../../_common/UserName';
import * as selectors from '../../state/selectors';

const Transfer = ({ transferUser, computerId, user, closeMenu }) => {
  const computersNotUsed = useSelector(selectors.computersNotUsed);

  const actionItems = computersNotUsed.map(item => ({
    text: item.name ? item.name : `Q${item.id}`,
    onClick: () =>
      transferUser({
        computerId,
        userId: user?.id,
        data: { computer: item.id },
        callback: closeMenu,
      }),
    dialog: {
      title: 'Are you sure you want to transfer?',
      // eslint-disable-next-line react/display-name
      content: () => (
        <Box textAlign="center">
          <Box color={palette.primary.red} fontWeight={600} fontSize={16} component="span" px={2}>
            {`Q${computerId}`}
          </Box>
          <img src={arrow} alt="" />
          <Box color={palette.primary.green} fontWeight={600} fontSize={16} component="span" px={2}>
            {item.name ? item.name : `Q${item.id}`}
          </Box>
          <Box mt={2}>
            <UserName user={user} />
            <Typography color="textSecondary" component="span" style={{ fontWeight: 'bold' }}>
              {` | ${user?.phone}`}
            </Typography>
          </Box>
        </Box>
      ),
    },
  }));

  return (
    <ButtonWithOptions
      actionItems={actionItems}
      buttonProps={{
        className: 'fontWeightNormal',
        fullWidth: true,
        'aria-controls': 'transfer',
        style: {
          justifyContent: 'flex-start',
          paddingLeft: 16,
        },
      }}
      menuItemProps={{
        style: {
          minWidth: 165,
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 35,
        horizontal: 'right',
      }}
    >
      Transfer to...
    </ButtonWithOptions>
  );
};

export default Transfer;
