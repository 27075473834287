import { all, put, call, takeEvery } from 'redux-saga/effects';
import { paths } from '../../../config';
import { clearToken, setToken } from '../../../utils/auth';
import getStringFromError from '../../../utils/getStringFromError';
import * as types from './types';
import * as actions from './actions';
import { authenticate, logout } from '../api';

function* loginUser({ payload }) {
  try {
    const { values, history } = payload;
    const { data } = yield call(authenticate, values);
    if (data.user.role !== 'CLIENT') {
      setToken(data.accessToken);
      yield put(actions.LoginSuccess(data));
      history.push(paths.dashboard);
    } else {
      yield put(actions.LoginFailure('Forbidden'));
    }
  } catch (err) {
    console.error('loginUser', err);
    yield put(actions.LoginFailure(getStringFromError(err)));
  }
}

function* logoutRequest({ payload }) {
  try {
    const { history } = payload;
    const { data } = yield call(logout);

    clearToken();
    yield put(actions.LogoutSuccess(data));
    history.push(paths.signin);
  } catch (err) {
    console.error('LogoutFailure', err);
    yield put(actions.LogoutFailure(getStringFromError(err)));
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(types.LOGIN_REQUEST, loginUser),
    takeEvery(types.LOGOUT_REQUEST, logoutRequest),
  ]);
}
