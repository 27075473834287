export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST';
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS';
export const ALL_USERS_FAILURE = 'ALL_USERS_FAILURE';

export const LOAD_ALL_USERS_SUCCESS = 'LOAD_ALL_USERS_SUCCESS';

export const SEARCH_ALL_USERS_REQUEST = 'SEARCH_ALL_USERS_REQUEST';
export const SEARCH_ALL_USERS_SUCCESS = 'SEARCH_ALL_USERS_SUCCESS';
export const SEARCH_ALL_USERS_FAILURE = 'SEARCH_ALL_USERS_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE';

export const REMOVE_USER_REQUEST = 'REMOVE_USER_INFO_REQUEST';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_INFO_SUCCESS';
export const REMOVE_USER_FAILURE = 'REMOVE_USER_INFO_FAILURE';

export const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const ADD_CARD_FAILURE = 'ADD_CARD_FAILURE';

export const EDIT_CARD_REQUEST = 'EDIT_CARD_REQUEST';
export const EDIT_CARD_SUCCESS = 'EDIT_CARD_SUCCESS';
export const EDIT_CARD_FAILURE = 'EDIT_CARD_FAILURE';

export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';

export const ADD_NOTES_REQUEST = 'ADD_NOTES_REQUEST';
export const ADD_NOTES_SUCCESS = 'ADD_NOTES_SUCCESS';
export const ADD_NOTES_FAILURE = 'ADD_NOTES_FAILURE';

export const EDIT_NOTES_REQUEST = 'EDIT_NOTES_REQUEST';
export const EDIT_NOTES_SUCCESS = 'EDIT_NOTES_SUCCESS';
export const EDIT_NOTES_FAILURE = 'EDIT_NOTES_FAILURE';

export const DELETE_NOTES_REQUEST = 'DELETE_NOTES_REQUEST';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_FAILURE = 'DELETE_NOTES_FAILURE';

export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const CLEAR_INVOICE_STATE = 'CLEAR_INVOICE_STATE';

export const REFILL_INVOICE_REQUEST = 'REFILL_INVOICES_REQUEST';
export const REFILL_INVOICE_SUCCESS = 'REFILL_INVOICES_SUCCESS';
export const REFILL_INVOICE_FAILURE = 'REFILL_INVOICES_FAILURE';

export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';

export const REFILLS_REQUEST = 'REFILLS_REQUEST';
export const REFILLS_SUCCESS = 'REFILLS_SUCCESS';
