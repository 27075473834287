import { Box, Grid, Paper, Table, TableBody, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';

import { palette } from '../../../styles/palette';
import TableHeadRow from '../TableHeadRow';

const useStyles = makeStyles(theme => ({
  cell: {
    borderBottomColor: theme.palette.primary.lightestBlue,
    fontWeight: 600,
    color: theme.palette.grey.A700,
    paddingTop: 2,
    paddingBottom: 2,
  },
}));

const TableWithCustomRows = ({ title, data, TableRow, headings, BeforeTable, TitleButton }) => {
  const classes = useStyles();

  return (
    <>
      {BeforeTable}
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h2" color="primary">
          {title}
        </Typography>
        {TitleButton}
      </Grid>
      <Paper square elevation={0} component={Box} border={`1px solid ${palette.borders.main}`}>
        <Table size="small" aria-label="a dense table">
          <TableHeadRow cells={headings} />
          <TableBody>
            {data
              ?.sort((item, nextItem) => item.price - nextItem.price)
              .map(row => (
                <TableRow key={row.id} row={row} cellProps={{ className: classes.cell }} />
              ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

TableWithCustomRows.defaultProps = {
  data: [],
  BeforeTable: null,
  TitleButton: null,
};

TableWithCustomRows.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.any),
  TableRow: PropTypes.func.isRequired,
  headings: PropTypes.arrayOf(PropTypes.string).isRequired,
  BeforeTable: PropTypes.node,
  TitleButton: PropTypes.node,
};

export default TableWithCustomRows;
