/* eslint no-console: 0 */
import { all, put, call, takeEvery, select } from 'redux-saga/effects';
import getStringFromError from '../../../utils/getStringFromError';
import * as api from '../api';
import * as types from './types';
import * as actions from './actions';
import * as actionsLayout from '../../layout/state/actions';

function* PosInvoiceRequest({ payload }) {
  try {
    const { profile } = yield select();
    const { data } = yield call(api.posInvoice, {
      ...payload,
      userId: profile.myUser.userId,
      couponId: 1,
    });

    yield put(actions.posInvoiceSuccess(data));
    yield put(
      actionsLayout.enqueueSnackbar({
        message: 'Сhecked.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    console.error('posInvoiceSuccess', err);

    yield put(
      actionsLayout.enqueueSnackbar({
        message: getStringFromError(err),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
}

export default function* saga() {
  yield all([takeEvery(types.POS_INVOICE_REQUEST, PosInvoiceRequest)]);
}
