import * as types from './types';
import { getToken } from '../../../utils/auth';

const initialState = {
  loading: false,
  errors: {},
  accessToken: getToken(),
  success: false,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.REMOVE_TOKEN: {
      return { ...state, accessToken: null };
    }
    case types.LOGIN_REQUEST: {
      return { ...state, loading: true, authFailed: false, errors: false };
    }
    case types.LOGIN_SUCCESS: {
      return { ...state, ...payload, loading: false, errors: false };
    }
    case types.LOGIN_FAILURE: {
      return { ...state, errors: { login: payload }, loading: false };
    }
    case types.LOGOUT_REQUEST: {
      return { ...state, loading: true };
    }
    case types.LOGOUT_SUCCESS: {
      return { ...state, ...payload, loading: false };
    }
    case types.LOGOUT_FAILURE: {
      return { ...state, errors: payload, success: false, loading: false };
    }
    default:
      return state;
  }
};

export default authReducer;
