import { createMuiTheme } from '@material-ui/core';

import MuiButtons from './buttons/MuiButtons';
import MuiCheckbox from './checkbox/MuiCheckbox';
import MuiSwitch from './checkbox/MuiSwitch';
import MuiExpansionPanel from './ExpansionPanel/MuiExpansionPanel';
import MuiExpansionPanelDetails from './ExpansionPanel/MuiExpansionPanelDetails';
import MuiExpansionPanelSummary from './ExpansionPanel/MuiExpansionPanelSummary';
import globalStyle from './globalStyle';
import MuiInputBase from './inputs/MuiInputBase';
import MuiFormLabel from './label/MuiFormLabel';
import { palette } from './palette';
import MuiSelect from './select/MuiSelect';
import MuiTableRow from './table/MuiTableRow';
import MuiDialog from './dialog/MuiDialog';
import MuiDialogTitle from './dialog/MuiDialogTitle';
import MuiDialogContent from './dialog/MuiDialogContent';
import MuiDialogActions from './dialog/MuiDialogActions';
import typography from './typography';

const MuiTheme = createMuiTheme({
  palette: { ...palette },

  typography: { ...typography },

  overrides: {
    MuiCssBaseline: {
      '@global': { ...globalStyle },
    },
    MuiExpansionPanel: { ...MuiExpansionPanel },
    MuiExpansionPanelSummary: { ...MuiExpansionPanelSummary },
    MuiExpansionPanelDetails: { ...MuiExpansionPanelDetails },
    MuiButton: { ...MuiButtons },
    MuiFormLabel: { ...MuiFormLabel },
    MuiInputBase: { ...MuiInputBase },
    MuiCheckbox: { ...MuiCheckbox },
    MuiSelect: { ...MuiSelect },
    MuiSwitch: { ...MuiSwitch },
    MuiTableRow: { ...MuiTableRow },
    MuiDialog: { ...MuiDialog },
    MuiDialogTitle: { ...MuiDialogTitle },
    MuiDialogContent: { ...MuiDialogContent },
    MuiDialogActions: { ...MuiDialogActions },
  },
});

export default MuiTheme;
