import React from 'react';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`wrapped-tabpanel-${index}`}
    aria-labelledby={`wrapped-tab-${index}`}
    style={{ width: '100%' }}
    {...other}
  >
    {value === index && <div>{children}</div>}
  </div>
);

export default TabPanel;
