import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import AutoSizer from 'react-virtualized-auto-sizer';

import LoadingOrError from '../../_atoms/LoadingOrError';

/**
 * Table with infinity scroll.
 *
 * We didn't use any <table> semantic because library wrappers would break it anyway.
 *
 * HeaderRow must be inside table for column alignment not break when there is scrollbar.
 *
 * We had to do hack with AutoSizer and useState because when we placed InfiniteScroll inside AutoSizer,
 * the height from AutoSizer was ok, but in real DOM InfiniteScroll inline style was 0px.
 */

const InfinityTable = ({ dataLength, next, hasMore, children }) => {
  const [autoHeight, setAutoHeight] = useState(500);

  return (
    <div style={{ height: 'calc(100% - 16px - 43px)' }}>
      <AutoSizer>
        {({ height }) => {
          setAutoHeight(height);
          return null;
        }}
      </AutoSizer>
      <InfiniteScroll
        dataLength={dataLength}
        next={next}
        hasMore={hasMore}
        loader={<LoadingOrError size={20} />}
        height={autoHeight}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};

InfinityTable.propTypes = {
  dataLength: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default InfinityTable;
