import * as types from './types';

export const getAllPCRequest = payload => {
  return {
    type: types.GET_ALL_PC_REQUEST,
    payload,
  };
};

export const getAllPCSuccess = payload => {
  return {
    type: types.GET_ALL_PC_SUCCESS,
    payload,
  };
};

export const getUsersRequest = payload => {
  return {
    type: types.GET_USERS_PC_REQUEST,
    payload,
  };
};

export const getUsersSuccess = payload => {
  return {
    type: types.GET_USERS_PC_SUCCESS,
    payload,
  };
};

export const startSessionRequest = payload => {
  return {
    type: types.START_SESSION_REQUEST,
    payload,
  };
};

export const startSessionSuccess = payload => {
  return {
    type: types.START_SESSION_SUCCESS,
    payload,
  };
};

export const endSessionRequest = payload => {
  return {
    type: types.END_SESSION_REQUEST,
    payload,
  };
};

export const endSessionSuccess = payload => {
  return {
    type: types.END_SESSION_SUCCESS,
    payload,
  };
};

export const cleanComputerUsersSearchList = payload => {
  return {
    type: types.CLEAN_PC_USERS_SEARCH_LIST,
    payload,
  };
};

export const transferUserRequest = payload => {
  return {
    type: types.TRANSFER_USER_REQUEST,
    payload,
  };
};

export const transferUserSuccess = payload => {
  return {
    type: types.TRANSFER_USER_SUCCESS,
    payload,
  };
};

export const editPcRequest = payload => {
  return {
    type: types.EDIT_PC_REQUEST,
    payload,
  };
};

export const editPcSuccess = payload => {
  return {
    type: types.EDIT_PC_SUCCESS,
    payload,
  };
};

export const arrangeComputersRequest = payload => ({
  type: types.ARRANGE_PC_REQUEST,
  payload,
});

export const arrangeSingleComputerSuccess = payload => ({
  type: types.ARRANGE_PC_SUCCESS,
  payload,
});

export const wsInitializeChannel = () => ({ type: types.WS_INITIALIZE_CHANNEL });

export const wsSessionStarted = payload => ({ type: types.WS_SESSION_STARTED, payload });
export const wsSessionFinished = payload => ({ type: types.WS_SESSION_FINISHED, payload });
export const wsСomputerStatusChanged = payload => ({ type: types.WS_STATUS_CHANGED, payload });

export const wsSessionStartSuccess = payload => ({
  type: types.WS_SESSION_STARTED_SUCCESS,
  payload,
});

export const wsSessionFinishSuccess = payload => ({
  type: types.WS_SESSION_FINISHED_SUCCESS,
  payload,
});
export const wsСomputerStatusChangedSuccess = payload => ({
  type: types.WS_STATUS_CHANGED_SUCCESS,
  payload,
});

export const setArrangeMode = payload => ({
  type: types.ARRANGE_PC_MODE,
  payload,
});
