import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { notifications } from '../../../dictionary';

const LoadingOrError = ({ error, size, ...rest }) => (
  <div className="loadingErrorContainer" {...rest}>
    {error ? (
      <Typography color="error">
        {typeof error === 'string' ? error : notifications.errors.default}
      </Typography>
    ) : (
      <CircularProgress disableShrink size={size} />
    )}
  </div>
);

LoadingOrError.defaultProps = {
  error: false,
  size: 40,
};

LoadingOrError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LoadingOrError;
