import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../state/actions';

const SocketInitializer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.wsInitializeChannel());
  }, []);

  return null;
};

export default SocketInitializer;
