export const objToQueryString = (obj = {}, splitter = '=', joiner = '&') => {
  const str = Object.keys(obj).map(key => {
    if (key === 'search' && !obj[key]) {
      return null;
    }

    const value = typeof obj[key] === 'string' ? obj[key].replace('+', '%2B') : obj[key];

    if (key === 'subpath') {
      return `${value}`;
    }

    return `${key}${splitter}${value}`;
  });

  let string = str.join(joiner);
  string = string.replace('&&', '&').replace('&/', '/');

  return string;
};

export const queryStringToObj = string => {
  let query = '';
  let queryObj = {};

  if (string) {
    query = string.substring(1);

    // if there is '&' in the end of string, JSON.parse throws error
    if (query.substr(-1) === '&') {
      query = query.substring(0, query.length - 1);
    }
    if (query.substr(0, 1) === '&') {
      query = query.substring(0, 1);
    }

    if (query.substr(0, 1) === '/') {
      query = query.substring(0, 1);
    }

    if (query) {
      queryObj = JSON.parse(
        `{"${decodeURI(query)
          // .replace(/%2B/g, '+')
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          // .replace(/\//g, '","subpath":"')
          .replace(/=/g, '":"')}"}`,
      );
    }
  }

  return queryObj;
};

export const userQueryStringToObj = (query = '') => {
  const obj = {};
  // eslint-disable-next-line array-callback-return
  query.split('+').map(param => {
    if (param) {
      // eslint-disable-next-line prefer-destructuring
      obj[param.split(':')[0]] = param.split(':')[1];
    }
  });
  return obj;
};
