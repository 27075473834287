import { color } from '../theme';

const MuiExpansionPanel = {
  root: {
    borderBottom: `1px solid ${color.lightestBlue}`,
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      backgroundColor: 'transparent',
    },
  },
  rounded: {
    backgroundColor: 'transparent',
  },
};

export default MuiExpansionPanel;
