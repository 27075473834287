import React from 'react';
import { Box } from '@material-ui/core';
import Wrapper from '../app/_common/Wrapper';
import ComputersContainer from '../app/computers/containers/ComputersContainer';
import OptionsContainer from '../app/computers/containers/OptionsContainer';

const PosPage = () => (
  <Wrapper>
    <Box textAlign="right">
      <OptionsContainer />
    </Box>
    <ComputersContainer />
  </Wrapper>
);
export default PosPage;
