import React, { useRef } from 'react';

import plusIcon from '../../../../assets/images/icons/plus.svg';
import { palette } from '../../../../styles/palette';
import ModalDialog from '../../../_common/Dialog';
import Form from './Form';

const AddButton = ({ buttonText = 'Add', onSubmit, type, noIcon, buttonProps, initialValues }) => {
  const formRef = useRef();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit'));
    }
  };

  return (
    <ModalDialog
      title="Add / Edit item"
      submitLabel="Save"
      onHandleSubmit={handleSubmit}
      BtnShowDialogTitle={buttonText}
      buttonProps={{
        startIcon: !noIcon && <img src={plusIcon} alt="" />,
        color: 'primary',
        size: 'large',
        style: {
          color: palette.primary.lightBlue,
        },
        ...buttonProps,
      }}
      color="secondary"
      Content={({ closeDialog }) => (
        <Form
          formRef={formRef}
          closeDialog={closeDialog}
          onSubmit={onSubmit}
          type={type}
          initial={initialValues}
        />
      )}
    />
  );
};

export default AddButton;
