import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { paths } from '../../config';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { accessToken } = useSelector(state => state.auth);

  if (!accessToken) {
    return <Redirect {...rest} to={paths.signin} />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
};

export default PrivateRoute;
