import { color } from '../theme';

const MuiCheckbox = {
  root: {
    color: color.lightestBlue,
    width: 16,
    height: 16,
    marginRight: 8,
  },
  colorSecondary: {
    '&$checked': {
      color: color.blue,
    },
  },
};

export default MuiCheckbox;
