import * as types from './types';

const initialState = {
  data: {},
  loading: false,
  meta: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ITEMS_REQUEST: {
      return { ...state, loading: true };
    }

    case types.GET_ITEMS_SUCCESS: {
      return { ...state, data: payload, loading: false };
    }

    case types.DELETE_ITEM_SUCCESS: {
      const { id } = payload;

      const itemToDelete = state.data[payload.type].findIndex(element => element.id === id);

      return {
        ...state,
        data: {
          ...state.data,
          [payload.type]: [
            ...state.data[payload.type].slice(0, itemToDelete),
            ...state.data[payload.type].slice(itemToDelete + 1),
          ],
        },
      };
    }

    case types.ADD_ITEM_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.type]: [...state.data[payload.type], payload],
        },
      };
    }

    case types.EDIT_ITEM_SUCCESS: {
      const itemToChange = state.data[payload.type].findIndex(element => element.id === payload.id);

      return {
        ...state,
        data: {
          ...state.data,
          [payload.type]: [
            ...state.data[payload.type].slice(0, itemToChange),
            payload,
            ...state.data[payload.type].slice(itemToChange + 1),
          ],
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
