import { all, put, call, takeEvery } from 'redux-saga/effects';
import getStringFromError from '../../../utils/getStringFromError';
import showNotification from '../../../utils/showNotification';

import * as types from './types';
import * as actions from './actions';
import * as api from '../api';

function* getItemsRequest() {
  try {
    const { data } = yield call(api.items);

    yield put(actions.getItemsSuccess(data));
  } catch (err) {
    console.error('getItemsRequest', err);
  }
}

function* addItemRequest({ payload }) {
  try {
    const { data } = yield call(api.addItem, payload.values);

    if (payload.callback) {
      payload.callback();
    }

    yield put(actions.addItemSuccess(data));

    yield put(showNotification(`${payload.values.name} added.`, 'success'));
  } catch (err) {
    console.error('addItemRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* editItemRequest({ payload }) {
  try {
    const { data } = yield call(api.editItem, payload.values);

    if (payload.callback) {
      payload.callback();
    }

    yield put(actions.editItemSuccess(data));

    yield put(showNotification(`${payload.values.name} updated.`, 'success'));
  } catch (err) {
    console.error('editItemRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* deleteItemRequest({ payload }) {
  try {
    yield call(api.deleteItem, payload.item.id);

    if (payload.callback) {
      payload.callback();
    }

    yield put(actions.deleteItemSuccess(payload.item));

    yield put(showNotification(`${payload.item.name} deleted.`, 'success'));
  } catch (err) {
    console.error('deleteItemRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(types.GET_ITEMS_REQUEST, getItemsRequest),
    takeEvery(types.ADD_ITEM_REQUEST, addItemRequest),
    takeEvery(types.EDIT_ITEM_REQUEST, editItemRequest),
    takeEvery(types.DELETE_ITEM_REQUEST, deleteItemRequest),
  ]);
}
