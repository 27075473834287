/* eslint-disable */
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import React from 'react';

import cropTimeString from '../../../../utils/cropTimeString';
import SvgSymbol from '../../../_atoms/InlineSvg';
import LinkKeepPathname from '../../../_atoms/LinkKeepPathname';
import ActionIcons from './ActionIcons';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: theme.spacing(1),
  },
  pcContainer: ({ isInUserRoute, hasUser }) => ({
    alignSelf: 'center',
    justifySelf: 'center',
    position: 'relative',
    filter: isInUserRoute ? 'brightness(0.87)' : 'none',
    '&:hover': {
      filter: hasUser || isInUserRoute ? 'brightness(0.87)' : 'none',
    },
  }),
  pcText: ({ inUse, editMode }) => ({
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 'bold',
    color: editMode
      ? theme.palette.primary.lightBlue
      : inUse === 'IN_USE'
      ? theme.palette.primary.red
      : inUse === 'ON'
      ? theme.palette.primary.green
      : theme.palette.primary.off,
  }),
  pcPath: ({ inUse, editMode }) => ({
    width: 64,
    height: 58,
    fill: editMode
      ? theme.palette.primary.lightBlue
      : inUse === 'IN_USE'
      ? theme.palette.primary.red
      : inUse === 'ON'
      ? theme.palette.primary.green
      : theme.palette.primary.off,
  }),

  isAdminAccessBtn: {
    position: 'absolute',
    zIndex: 2,
    top: 7,
    // right: 7,
    right: 10,
  },

  computerBtn: {
    padding: 0,
  },

  isAdminIcon: {
    width: 8,
    height: 8,
    fill: theme.palette.primary.main,
  },

  availableBtn: {
    position: 'absolute',
    zIndex: 2,
    top: 7,
    // left: 7,
    left: 10,
  },

  availableIcon: ({ isLocked }) => ({
    fill: isLocked === 'LOCKED' ? theme.palette.primary.red : '#FFB03D',
    width: 10,
    height: 10,
  }),
}));

const useStylesTooltip = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 12,
    padding: '6px 12px',
    color: theme.palette.primary.contrastText,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: 5,
  },

  arrow: {
    color: theme.palette.primary.main,
  },
}));

const StyledTooltip = props => {
  const classesTolltip = useStylesTooltip();
  return (
    <Tooltip arrow placement="bottom-start" enterDelay={0} classes={classesTolltip} {...props} />
  );
};

const ComputerItem = ({ pc, startSession, endSession, transferUser, onHandlePcEdit, editMode }) => {
  const { search } = useLocation();

  const isInUserRoute = search.includes(pc?.user?.id);
  const inUse = pc?.status;
  const hasUser = pc?.user;
  const isLocked = pc.available;
  const classes = useStyles({ inUse, isLocked, editMode, isInUserRoute, hasUser });

  const onStartSession = ({ value, callback }) => {
    startSession({ data: { computerId: pc.id, userId: value }, callback });
  };

  const balance = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(pc?.user?.balance);

  const pcName = pc?.name || `Q${pc.id}`;
  const pcNameElement =
    pcName.length > 5 ? (
      <StyledTooltip title={pcName}>
        <div className={classes.pcText}>{`${pcName.substr(0, 4)}...`}</div>
      </StyledTooltip>
    ) : (
      <div className={classes.pcText}>{pcName}</div>
    );

  return (
    <div style={{ textAlign: 'center' }}>
      <ActionIcons
        pc={pc}
        onStartSession={onStartSession}
        endSession={endSession}
        transferUser={transferUser}
        editMode={editMode}
        inUse={inUse}
        hasUser={hasUser}
        onHandlePcEdit={onHandlePcEdit}
      />
      <div className={classes.pcContainer}>
        {hasUser && !editMode ? (
          <LinkKeepPathname query={`userRoute=${pc.user.id}`}>
            <StyledTooltip
              title={
                pc.user && (
                  <>
                    {pc.user.firstName} {pc.user.lastName} ({pc.user.gender[0]}) <br />
                    {pc.user.phone} <br />
                    Time: {cropTimeString(pc.user?.time)}
                    <br />
                    Balance: {balance}
                  </>
                )
              }
              className={classes.tooltipInfo}
            >
              <IconButton size="small" className={classes.computerBtn} disableRipple>
                <SvgSymbol iconName="computer" className={classes.pcPath} />
              </IconButton>
            </StyledTooltip>
            {pcNameElement}
          </LinkKeepPathname>
        ) : (
          <>
            <SvgSymbol iconName="computer" className={classes.pcPath} />
            {pcNameElement}
          </>
        )}
        {pc?.isAdminAccess && !editMode && (
          <StyledTooltip title="Admin access">
            <IconButton size="small" className={classes.isAdminAccessBtn}>
              <SvgSymbol iconName="adminAccess" className={classes.isAdminIcon} />
            </IconButton>
          </StyledTooltip>
        )}

        {pc?.available !== 'UNLOCKED' && !editMode && (
          <StyledTooltip
            title={
              pc.available === 'LOCKING' ? (
                <>
                  Computer will be locked at the end <br />
                  {' of current session.'}
                </>
              ) : (
                'Locked'
              )
            }
          >
            <IconButton size="small" className={classes.availableBtn}>
              <SvgSymbol iconName="available" className={classes.availableIcon} />
            </IconButton>
          </StyledTooltip>
        )}
      </div>
    </div>
  );
};

export default ComputerItem;
