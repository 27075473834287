const MuiDialogTitle = {
  root: {
    textAlign: 'center',
    padding: 0,
    whiteSpace: 'pre-line',
    marginBottom: 24,
  },
};

export default MuiDialogTitle;
