import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loading from './_atoms/LoadingOrError';
import PrivateRoute from '../pages/routes/PrivateRoute';
import PrivateRoutesArr from '../pages/routes/privateRoutesArray';
import { paths } from '../config';
// import Layout from './layout/components';

const Login = lazy(() => import('./auth/containers/LoginPageContainer'));

const App = () => {
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path={paths.signin} component={Login} />
          <PrivateRoute
            exact
            from={paths.signin}
            component={() => <Redirect to={paths.dashboard} />}
          />
          {PrivateRoutesArr.map((route, i) => (
            <PrivateRoute
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
