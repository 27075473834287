import { color } from './theme';

// eslint-disable-next-line import/prefer-default-export
export const palette = {
  primary: {
    light: color.primaryWhite,
    main: color.blue,
    dark: color.primaryBlue,
    contrastText: color.white,
    grey: color.primaryGrey,
    lightestBlue: color.lightestBlue,
    lightBlue: color.lightBlue,
    green: color.green,
    red: color.red,
    black: color.black,
    off: color.computerOFF,
  },
  // secondary: {
  // },
  // error: {
  // },
  borders: {
    light: color.borderGrey,
    main: color.lightestBlue,
  },
};
