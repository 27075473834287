import * as types from './types';

const initialState = {
  loading: false,
  error: false,
  myUser: {},
};

const profileReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.PROFILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.PROFILE_SUCCESS: {
      return {
        ...state,
        myUser: payload,
        loading: false,
      };
    }
    case types.PROFILE_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default profileReducer;
