/* eslint-disable */

/* eslint no-console: 0 */
import { all, put, call, takeEvery, debounce, select } from 'redux-saga/effects';

import { paths } from '../../../config';
import showNotification from '../../../utils/showNotification';
import getStringFromError from '../../../utils/getStringFromError';
import { objToQueryString, queryStringToObj } from '../../../utils/query';

import * as api from '../api';
import * as types from './types';
import * as actions from './actions';

function* AllUsersRequest() {
  try {
    const { data } = yield call(api.getUser);

    yield put(actions.AllUsersSuccess(data));
  } catch (err) {
    console.error('AllUsersRequest', err);

    const errorMessage = getStringFromError(err);

    yield put(actions.AllUsersFailure(errorMessage));
  }
}

function* RefillsRequest() {
  try {
    const { data } = yield call(api.getRefills);
    yield put(actions.RefillsSuccess(data));
  } catch (err) {
    console.error('refillsRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* SearchAllUsersRequest({ payload }) {
  try {
    const query = `?${objToQueryString(payload)}`;

    const { data, ...rest } = yield call(api.getUser, query);

    const { skip } = payload;

    if (skip) {
      yield put(
        actions.LoadAllUsersSuccess({ data, meta: { 'x-total': rest.headers['x-total'] } }),
      );
    } else {
      yield put(
        actions.SearchAllUsersSuccess({ data, meta: { 'x-total': rest.headers['x-total'] } }),
      );
    }
  } catch (err) {
    console.error('SearchAllUsersRequest', err);

    const errorMessage = getStringFromError(err);

    yield put(actions.SearchAllUsersFailure(errorMessage));
  }
}

function* CreateUserRequest({ payload }) {
  try {
    const { callbackAction, ...values } = payload;
    const { data } = yield call(api.createNewUser, { ...values, role: 'CLIENT' });

    const { firstName = 'First Name', lastName = 'Last Name' } = payload;
    yield put(actions.CreateUserSuccess(data));
    yield put(showNotification(`${firstName} ${lastName} added.`, 'success'));
    yield call(callbackAction, data.id);
  } catch (err) {
    console.error('CreateUserRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* UserInfoRequest({ payload }) {
  try {
    const query = `/${payload.match(/(\d+)/)[0]}`;
    const { data } = yield call(api.getUser, query);
    yield put(actions.UserInfoSuccess(data));
  } catch (err) {
    console.error('UserInfoRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* UpdateUserInfoRequest({ payload }) {
  try {
    const { ...values } = payload;
    const query = `/${values.id}`;
    const { data } = yield call(api.patchUser, query, values);
    yield put(actions.UpdateUserInfoSuccess(data));
    yield put(showNotification(`${values.firstName} ${values.lastName} updated.`, 'success'));
  } catch (err) {
    console.error('UpdateUserInfoRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* RemoveUserRequest({ payload }) {
  try {
    const { callbackAction, uQueryId } = payload;
    const query = `/${uQueryId}`;
    const { data } = yield call(api.deleteUser, query);
    const { users } = yield select();
    yield put(actions.RemoveUserSuccess(data));
    yield put(
      showNotification(
        `${users.currentUser.firstName} ${users.currentUser.lastName} deleted.`,
        'success',
      ),
    );
    yield call(callbackAction);
  } catch (err) {
    console.error('RemoveUserRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* addCardRequest({ payload }) {
  try {
    const { callback, successMessage, ...values } = payload;
    const { data } = yield call(api.addCard, values);

    if (callback) {
      callback();
    }

    yield put(actions.addCardSuccess(data));
    yield put(showNotification('Card added.', 'success'));
  } catch (err) {
    console.error('addCardRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}
function* editCardRequest({ payload }) {
  try {
    const { callback, successMessage, ...values } = payload;
    const { data } = yield call(api.editCard, values);

    if (callback) {
      callback();
    }

    yield put(actions.editCardSuccess(data));
    yield put(showNotification(successMessage, 'success'));
  } catch (err) {
    console.error('editCardRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* deleteCardRequest({ payload }) {
  try {
    const { callback, ...values } = payload;
    const { data } = yield call(api.deleteCard, values);

    if (callback) {
      callback();
    }

    yield put(actions.deleteCardSuccess(data));
    yield put(showNotification('Card deleted.', 'success'));
  } catch (err) {
    console.error('deleteCardRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* addNotesRequest({ payload }) {
  try {
    const { data } = yield call(api.addNote, payload);
    yield put(actions.addNotesSuccess(data));
    if (payload.callback) {
      payload.callback();
    }
  } catch (err) {
    console.error('addNotesRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* editNotesRequest({ payload }) {
  try {
    const { data } = yield call(api.editNote, payload);
    yield put(actions.editNotesSuccess(data));
  } catch (err) {
    console.error('editNotesRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* deleteNotesRequest({ payload }) {
  try {
    yield call(api.deleteNote, payload);
    const { noteId } = payload;
    yield put(actions.deleteNotesSuccess(noteId));
  } catch (err) {
    console.error('deleteNotesRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* RefillInvoiceRequest({ payload }) {
  const { callbackProps, ...values } = payload;
  const editInvoice =
    callbackProps && queryStringToObj(callbackProps.search).userRoute.match(/(\d+)/g)[1];

  try {
    const { profile } = yield select();

    // if there was invoice id in query - edit invoice
    const { data } = editInvoice
      ? yield call(api.editInvoice, {
          data: { items: values.items },
          id: queryStringToObj(callbackProps.search).userRoute.match(/(\d+)/g)[1],
        })
      : yield call(api.refillInvoice, {
          userId: profile.myUser.userId,
          ...values,
        });

    if (callbackProps) {
      const { search, pathname, history } = callbackProps;
      const { userQuery, userRoute } = queryStringToObj(search);

      const obj = {
        userQuery,
        userRoute: userRoute.match(/(\d+)/)[0] + paths.checkout + '/' + data.id,
      };

      history.push({
        pathname,
        search: `?${objToQueryString(obj)}`,
      });
    }

    yield put(actions.refillInvoiceSuccess(data));
  } catch (err) {
    console.error('refillInvoiceSuccess', err);
    if (err.response.data.error === 'Conflict' && editInvoice) {
      const { search, pathname, history } = callbackProps;
      const { userQuery, userRoute } = queryStringToObj(search);

      const queryIds = userRoute.match(/(\d+)/g);

      const obj = {
        userQuery,
        userRoute: queryIds[0] + paths.checkout + '/' + queryIds[1],
      };

      history.push({
        pathname,
        search: `?${objToQueryString(obj)}`,
      });
    }
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* getInvoiceRequest({ payload }) {
  try {
    const { data } = yield call(api.getInvoice, payload);

    yield put(actions.getInvoiceSuccess(data));
  } catch (err) {
    console.error('getInvoiceRequest', err);
  }
}

function* checkoutRequest({ payload }) {
  try {
    const { items, method, amount, invoiceId, isSplit, updateItems } = payload;

    // console.log('payload', payload);

    if (updateItems) {
      const itemsToSend = items.reduce((acc, it) => {
        acc.push({ amount: it.amount, id: it.item.id });
        return acc;
      }, []);

      yield call(api.editInvoice, { data: { items: itemsToSend }, id: invoiceId });

      yield put(showNotification('Items updated.'));
    }

    if (method === 'CHECK') {
      // console.log('pay with check');
      const { checkNum } = payload;
      yield call(api.postPayment, { data: { method, amount, checkNum }, id: invoiceId });
    } else if (method === 'CARD') {
      const { cardId, newCard, userId } = payload;

      // console.log('pay with card');

      if (newCard.cardNumber) {
        // console.log('pay with new card');

        const { users } = yield select();

        const { cardNumber, expMonth, expYear, cvc } = newCard;
        const { data } = yield call(api.addCard, {
          userId,
          data: {
            cardNumber,
            expMonth: parseInt(expMonth, 10),
            expYear: parseInt(expYear, 10),
            cvc,
            setDefault: false,
          },
        });

        yield put(showNotification('New card added.'));

        // very important part to pay from right card
        const postedCard = data.filter(
          ({ id: id1 }) => !users.currentUser.cards.some(({ id: id2 }) => id2 === id1),
        );

        yield call(api.postPayment, {
          data: {
            amount: amount,
            method: method,
            cardId: postedCard[0].id,
          },
          id: invoiceId,
        });

        yield put(actions.addCardSuccess(data));
      } else {
        // console.log(`pay with ${cardId} card`);
        yield call(api.postPayment, { data: { method, amount, cardId }, id: invoiceId });
      }
    } else {
      yield call(api.postPayment, { data: { method, amount }, id: invoiceId });
    }

    const { data } = yield call(api.getInvoice, invoiceId);

    yield put(actions.getInvoiceSuccess(data));

    if (isSplit) {
      yield put(showNotification('Split payment done.'));
    } else {
      yield put(showNotification('Payment done.', 'success'));
    }

    // if payment done and user is in user list, need to update it
    const { users } = yield select();
    if (users.invoice.total === users.invoice.received) {
      const userToChangeIndex = users.users.data.findIndex(
        element => element.id === users.currentUser.id,
      );

      if (userToChangeIndex !== -1) {
        const { data } = yield call(api.getUser, `/${users.currentUser.id}`);
        yield put(actions.UpdateUserInfoSuccess(data));
      }
    }

    // yield put(actions.checkoutSuccess(data));
  } catch (err) {
    console.error('checkoutRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

export default function* saga() {
  yield all([
    takeEvery(types.ALL_USERS_REQUEST, AllUsersRequest),
    debounce(400, types.SEARCH_ALL_USERS_REQUEST, SearchAllUsersRequest),
    takeEvery(types.CREATE_USER_REQUEST, CreateUserRequest),
    takeEvery(types.USER_INFO_REQUEST, UserInfoRequest),
    takeEvery(types.UPDATE_USER_INFO_REQUEST, UpdateUserInfoRequest),
    takeEvery(types.REMOVE_USER_REQUEST, RemoveUserRequest),
    takeEvery(types.DELETE_CARD_REQUEST, deleteCardRequest),
    takeEvery(types.EDIT_CARD_REQUEST, editCardRequest),
    takeEvery(types.ADD_CARD_REQUEST, addCardRequest),
    takeEvery(types.DELETE_NOTES_REQUEST, deleteNotesRequest),
    debounce(400, types.EDIT_NOTES_REQUEST, editNotesRequest),
    takeEvery(types.ADD_NOTES_REQUEST, addNotesRequest),
    takeEvery(types.REFILL_INVOICE_REQUEST, RefillInvoiceRequest),
    takeEvery(types.GET_INVOICE_REQUEST, getInvoiceRequest),
    takeEvery(types.CHECKOUT_REQUEST, checkoutRequest),
    takeEvery(types.REFILLS_REQUEST, RefillsRequest),
  ]);
}
