const MuiDialogTitle = {
  root: {
    '&:not(.MuiPickersModal-withAdditionalAction)': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      justifyContent: 'space-between',
      gridColumnGap: 24,
      padding: 0,
    },
  },

  spacing: {
    '& > :not(:first-child)': {
      marginLeft: 0,
    },
  },
};

export default MuiDialogTitle;
