import * as types from './types';

export const getPrintersRequest = payload => ({
  type: types.GET_PRINTERS_REQUEST,
  payload,
});

export const getPrintersSuccess = payload => ({
  type: types.GET_PRINTERS_SUCCESS,
  payload,
});
