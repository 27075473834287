import { del, get, patch, post } from '../../../api';
import { API_URIS } from '../../../config';

export const items = () => get(API_URIS.items.items);

export const addItem = values => post(`${API_URIS.items.items}`, values);

export const editItem = values => patch(`${API_URIS.items.items}/${values.id}`, values);

export const deleteItem = id => del(`${API_URIS.items.items}/${id}`);
