import * as types from './types';

const initialState = {
  data: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_NOTIFICATIONS_REQUEST: {
      return { ...state, loading: true };
    }

    case types.GET_NOTIFICATIONS_SUCCESS: {
      return { ...state, data: payload, loading: false };
    }

    case types.EDIT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
