import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPrintersRequest } from '../../app/printers/state/actions';
import { printers } from '../../app/printers/state/selectors';
import PrintersContext from './PrintersContext';

const PrintersProvider = ({ children }) => {
  const dispatch = useDispatch();

  const printersObj = useSelector(printers);

  useEffect(() => {
    dispatch(getPrintersRequest());
  }, []);

  return (
    <PrintersContext.Provider value={{ printers: printersObj }}>
      {children}
    </PrintersContext.Provider>
  );
};

export default PrintersProvider;
