import * as types from './types';

const initialState = {
  error: false,
  loading: false,
  couponId: 1,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.POS_INVOICE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.POS_INVOICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }
    case types.POS_INVOICE_FAILURE: {
      return {
        ...state,
        error: payload || true,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
