import { color } from '../theme';

const MuiInputBase = {
  root: {
    color: color.black,
    fontWeight: 600,
  },
};

export default MuiInputBase;
