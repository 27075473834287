import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  row: ({ gridTemplateColumns }) => ({
    display: 'grid',
    gridTemplateColumns,
    border: `1px solid ${theme.palette.borders.main}`,
    backgroundColor: theme.palette.primary.lightestBlue,
    '& div': {
      color: theme.palette.primary.lightBlue,
      fontSize: 12,
      fontWeight: 600,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  }),
}));

const HeaderRow = ({ cells, gridTemplateColumns }) => {
  const classes = useStyles({ gridTemplateColumns });

  return (
    <div className={classes.row}>
      {cells.map((cell, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>{cell}</div>
      ))}
    </div>
  );
};

HeaderRow.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.string).isRequired,
  gridTemplateColumns: PropTypes.string.isRequired,
};

export default HeaderRow;
