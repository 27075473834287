/* eslint-disable react/jsx-no-duplicate-props */
import { Grid, TextField, InputLabel, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { Fragment } from 'react';

import fieldArray from './fields';

const useStyles = makeStyles(theme => ({
  form: {
    width: 410,
  },
  input: {
    marginTop: 0,
    borderRadius: 2,
    borderColor: theme.palette.primary.lightestBlue,

    '& input, & textarea': {
      height: 30,
      padding: '0 12px',
    },
  },
}));

const Form = ({ formRef, onSubmit, closeDialog, initial }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: initial,
    onSubmit: values => {
      onSubmit({
        values,
        callback: closeDialog,
      });
    },
  });

  const { errors, touched, values, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      handleSubmit={handleSubmit}
      className={classes.form}
    >
      <Grid container spacing={1} alignItems="center">
        {fieldArray.map(field => {
          return (
            <Fragment key={field.name}>
              <Grid item xs={field.labelGridSize || 12}>
                <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
              </Grid>
              <Grid item xs={field.fieldGridSize || 12} key={field.name}>
                <TextField
                  fullWidth
                  name={field.name}
                  value={values[field.name]}
                  error={errors[field.name] && touched[field.name]}
                  multiline={4}
                  inputProps={{
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.input,
                  }}
                  id={field.name}
                  type={field.type}
                  disabled={field.disabled}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </form>
  );
};

export default Form;
