import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';

const ModalDialog = ({
  title,
  Content,
  submitLabel,
  cancelLabel,
  onHandleOpen,
  onHandleSubmit,
  onHandleCancel,
  BtnShowDialogTitle,
  buttonProps,
  dialogActionsProps,
  noActions,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = e => {
    e.stopPropagation();
    setOpen(true);
    if (onHandleOpen) {
      onHandleOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onHandleSubmit({ callback: handleClose });
  };

  const handleCancel = () => {
    if (onHandleCancel) {
      onHandleCancel();
    }
    handleClose();
  };

  return (
    <>
      <Button {...buttonProps} onClick={handleClickOpen}>
        {BtnShowDialogTitle || ''}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle disableTypography>
          <Typography variant="h2">{title}</Typography>
        </DialogTitle>
        {Content ? (
          <DialogContent>
            <Content closeDialog={handleClose} />
          </DialogContent>
        ) : null}
        {!noActions && (
          <DialogActions {...dialogActionsProps}>
            <Button onClick={handleCancel} color="primary" variant="outlined">
              {cancelLabel || 'Cancel'}
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained">
              {submitLabel}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

ModalDialog.defaultProps = {
  content: null,
  icon: null,
  color: 'default',
  variant: 'text',
  BtnShowDialogTitle: '',
  cancelLabel: 'Cancel',
};

ModalDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node,
  icon: PropTypes.node,
  submitLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
  onHandleSubmit: PropTypes.func.isRequired,
  BtnShowDialogTitle: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default ModalDialog;
