import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import orderBy from 'lodash.orderby';

import { objToQueryString, queryStringToObj } from '../../../utils/query';
import FilterForm from '../components/FilterForm';
import InfinityTable from '../components/InfinityTable';

import * as actions from '../state/actions';
import * as selectors from '../state/selectors';

const InvoicesContainer = () => {
  const dispatch = useDispatch();

  const { search, pathname } = useLocation();
  const history = useHistory();
  const { data, meta } = useSelector(selectors.invoices);

  const query = queryStringToObj(search);

  const initialParams = {
    take: 25,
  };

  const [requestOptions, setRequestOptions] = useState({
    ...initialParams,
    ...query,
  });

  const makeRequest = newQuery => {
    setRequestOptions(oldObj => ({
      ...oldObj,
      ...newQuery,
    }));
  };

  const searchRequest = values => {
    makeRequest(values);
  };

  const getInvoices = () => {
    {
      const { userQuery, userRoute, ...searchQuery } = requestOptions;
      dispatch(actions.allInvoicesRequest(searchQuery));
    }

    const { take, skip, ...pushQuery } = requestOptions;
    const { userQuery, userRoute } = query;

    // for proper navigation in both panels
    const obj = {
      ...pushQuery,
    };

    if (userQuery) {
      obj.userQuery = userQuery;
    }

    if (userRoute) {
      obj.userRoute = userRoute;
    }

    history.push({
      pathname,
      search: `?${objToQueryString(obj)}`,
    });
  };

  const unmountCleanup = () => {
    // need to clean reducer, because we push into existing state array on infinity scroll
    dispatch(actions.allInvoicesReset());

    // need to keep only user query on unmount
    // for proper navigation in both panels
    const { userQuery, userRoute } = query;

    const obj = {};

    if (userQuery) {
      obj.userQuery = userQuery;
    }

    if (userRoute) {
      obj.userRoute = userRoute;
    }

    history.push({
      search: `?${objToQueryString(obj)}`,
    });
  };

  useEffect(() => {
    getInvoices();
    return unmountCleanup;
  }, [requestOptions]);

  const deleteInvoice = (id, callback) => {
    dispatch(actions.deleteInvoiceRequest({ id, callback }));
  };

  const dataArray = orderBy(Object.values(data), ['id'], ['desc']);

  return (
    <>
      <FilterForm searchRequest={searchRequest} initialValues={requestOptions} />
      <InfinityTable
        next={getInvoices}
        total={parseInt(meta['x-total'], 10)}
        data={dataArray}
        deleteInvoice={deleteInvoice}
      />
    </>
  );
};
export default InvoicesContainer;
