import { color } from '../theme';

const MuiInputBase = {
  root: {
    borderRadius: 4,
    textTransform: 'initial',
    fontSize: '14px',
    transition: '0.3s',
    border: `1px solid ${color.borderGrey}`,
    color: `${color.black}`,
    marginTop: '5px',
    '&$error': {
      border: `1px solid ${color.red}`,
    },
    '&$focused': {
      border: `1px solid ${color.blue}`,
    },
    '& svg.arrow': {
      width: 13,
      height: 14,
      pointerEvents: 'none',
      fill: `${color.lightBlue}`,
      position: 'absolute',
      right: 5,
      cursor: 'pointer',
    },
  },
  input: {
    height: '44px',
    padding: '0 20px',
  },
};

export default MuiInputBase;
