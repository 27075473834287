const typography = {
  fontFamily: ['ProximaNovaRegular', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  fontSize: 16,
  fontWeight: 'normal',
  h1: {
    fontWeight: 800,
    fontSize: 30,
  },
  h2: {
    fontWeight: 600,
    fontSize: 16,
  },
  body1: {
    fontSize: 14,
  },
  body2: {
    fontSize: 12,
  },
  // subtitle1: {
  //   fontSize: 12,
  // },
  // subtitle2: {
  //   fontSize: 10,
  // },
  // overline: {
  //   fontSize: 10,
  //   color: colors.grey,
  // },
  // caption: {
  //   fontSize: 16,
  //   fontWeight: 500,
  // },
};

export default typography;
