import React from 'react';

import Wrapper from '../app/_common/Wrapper';
import NotificationsContainer from '../app/notifications/containers/NotificationsContainer';

const NotificationsPage = () => {
  return (
    <Wrapper>
      <NotificationsContainer />
    </Wrapper>
  );
};
export default NotificationsPage;
