import React from 'react';
import PropTypes from 'prop-types';

import Sprite from '../../../assets/images/sprite.svg';
import { palette } from '../../../styles/palette';

const SvgSymbol = ({ iconName, className, fill, ...rest }) => {
  let fillColor = fill;

  switch (fill) {
    case 'computerOFF':
      fillColor = palette.primary.off;
      break;
    case 'lightBlue':
      fillColor = palette.primary.lightBlue;
      break;
    case 'lightestBlue':
      fillColor = palette.primary.lightestBlue;
      break;
    case 'light':
      fillColor = palette.primary.light;
      break;
    case 'primary':
      fillColor = palette.primary.main;
      break;
    default:
      fillColor = fill;
  }

  return (
    <svg className={className} fill={fillColor} {...rest}>
      <use xlinkHref={`${Sprite}#icon-${iconName}`} />
    </svg>
  );
};
SvgSymbol.defaultProps = {
  className: '',
  iconName: '',
};
SvgSymbol.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
};

export default SvgSymbol;
