import PropTypes from 'prop-types';
import React from 'react';

import { TypeInvoice } from '../../../../types';
import InfinityTable from '../../../_common/InfinityTable';
import HeaderRow from '../Header';
import InvoiceRow from '../InvoiceRow';

const InvoiceTable = ({ next, total, data, deleteInvoice }) => {
  const headings = ['Date', 'Time', 'Invoice #', 'Account #', 'Event', '$', ''];

  const gridTemplateColumns = '110px 105px 90px 130px auto 80px 30px';

  return (
    <InfinityTable dataLength={data.length} next={next} hasMore={data.length < total}>
      <div style={{ width: 'calc(100% - 2px)' }}>
        <HeaderRow cells={headings} gridTemplateColumns={gridTemplateColumns} />
        <div style={{ backgroundColor: '#E8EEFF' }}>
          {data.map(invoice => (
            <InvoiceRow
              invoice={invoice}
              key={invoice.id}
              gridTemplateColumns={gridTemplateColumns}
              deleteInvoice={deleteInvoice}
            />
          ))}
        </div>
      </div>
    </InfinityTable>
  );
};

InvoiceTable.propTypes = {
  deleteInvoice: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(TypeInvoice).isRequired,
};

export default InvoiceTable;
