import React, { useState } from 'react';
import { Button, IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import Dialog from '../Dialog';

const useStyles = makeStyles(theme => ({
  triangle: {
    overflow: 'visible',
    border: `1px solid ${theme.palette.primary.lightestBlue}`,
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '-16.5px',
      left: 32,
      borderBottom: `8px solid ${theme.palette.primary.lightestBlue}`,
      borderRight: '8px solid transparent',
      borderLeft: '8px solid transparent',
      borderTop: '8px solid transparent',
      zIndex: 10,
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '-14px',
      left: 33,
      borderBottom: `7px solid ${theme.palette.primary.contrastText}`,
      borderRight: '7px solid transparent',
      borderLeft: '7px solid transparent',
      borderTop: '7px solid transparent',
      zIndex: 10,
    },

    '& [aria-selected="true"]': {
      backgroundColor: theme.palette.primary.lightestBlue,
    },
  },

  paperInner: {
    maxHeight: 160,
    overflowX: 'hidden',
    overflowY: 'auto',

    '& $button': {
      '&:hover .MuiButton-label': {
        color: `${theme.palette.primary.main}`,
      },
    },
  },
  paperOptions: {
    '&::before': {
      left: 'auto',
      right: 5,
    },
    '&::after': {
      left: 'auto',
      right: 6,
    },
  },

  confirmButton: {
    padding: '6px 16px',
    width: '100%',
    display: 'block',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.lightestBlue} !important`,
    },
  },
}));

const ButtonWithOptions = ({
  children,
  icon,
  buttonProps,
  actionItems,
  menuItemProps,
  transformOrigin,
  anchorOrigin,
}) => {
  const classes = useStyles();
  const [popoverClass, setPopoverClass] = useState(classes.paper);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    if (event.currentTarget.getAttribute('aria-controls') === 'options') {
      setPopoverClass(classes.paperOptions);
    }
    if (event.currentTarget.getAttribute('aria-controls') === 'transfer') {
      setPopoverClass(classes.paperInner);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {icon ? (
        <IconButton
          aria-haspopup="true"
          onClick={handleClick}
          aria-selected={Boolean(anchorEl)}
          {...buttonProps}
        >
          {children}
        </IconButton>
      ) : (
        <Button
          aria-haspopup="true"
          onClick={handleClick}
          aria-selected={Boolean(anchorEl)}
          {...buttonProps}
        >
          {children}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: `${popoverClass} ${classes.triangle}`,
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {actionItems?.map((item, i) => {
          if (item.Component) {
            const { Component } = item;
            return <Component key={item.i} closeMenu={handleClose} />;
          }
          if (item.dialog) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Dialog
                key={item.i}
                title={item.dialog.title}
                submitLabel="Yes"
                onHandleOpen={handleClose}
                onHandleSubmit={({ callback }) => {
                  item.onClick({ callback });
                  handleClose();
                }}
                onHandleCancel={() => {
                  handleClose();
                }}
                BtnShowDialogTitle={item.text}
                Content={item.dialog.content}
                buttonProps={{
                  className: `fontWeightNormal ${classes.confirmButton}`,
                  classes: {
                    root: classes.button,
                  },
                  ...menuItemProps,
                }}
                noActions={item.dialog.noActions}
                closeMenu={handleClose}
              />
            );
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={i} onClick={item.onClick} {...menuItemProps}>
              {item.text}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ButtonWithOptions;
