import * as types from './types';

export const posInvoiceRequest = payload => {
  return {
    type: types.POS_INVOICE_REQUEST,
    payload,
  };
};

export const posInvoiceSuccess = payload => {
  return {
    type: types.POS_INVOICE_SUCCESS,
    payload,
  };
};

export const posInvoiceFailure = payload => {
  return {
    type: types.POS_INVOICE_FAILURE,
    payload,
  };
};
