import {
  Button,
  MenuItem,
  Typography,
  Box,
  Grid,
  makeStyles,
  InputBase,
  Select,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as PropTypes from 'prop-types';
import React from 'react';

import searchIcon from '../../../../assets/images/icons/search.svg';
import SvgSymbol from '../../../_atoms/InlineSvg';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.borders.main}`,
  },
  search: {
    backgroundColor: theme.palette.common.white,
    marginTop: 0,
  },
  select: {
    border: '0 !important',
  },
  selectContainer: {
    textAlign: 'right',
  },
}));

// all this render fields are fixes, maybe there is a better solution

const RenderField = ({ field, form: { errors, touched } }) => {
  const classes = useStyles();
  return (
    <InputBase
      {...field}
      className={`search ${classes.search}`}
      type="text"
      placeholder="Search"
      fullWidth
      error={errors.search && touched.search}
      startAdornment={<img src={searchIcon} alt="" />}
    />
  );
};

const RenderSelectField = ({ field }) => {
  const classes = useStyles();

  return (
    <Select
      disableUnderline
      autoWidth
      IconComponent={() => <SvgSymbol iconName="arrow" className="arrow" />}
      defaultValue="ACCOUNT"
      className={classes.select}
      {...field}
    >
      <MenuItem value="ACCOUNT">
        <Typography color="primary">Account #</Typography>
      </MenuItem>
      <MenuItem value="INVOICE">
        <Typography color="primary">Invoice #</Typography>
      </MenuItem>
    </Select>
  );
};

const FilterForm = ({ searchRequest, initialValues }) => {
  const classes = useStyles();

  return (
    <Box my={1.5}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          searchRequest(values);
          setSubmitting(false);
        }}
      >
        {() => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <Grid container className={classes.searchContainer}>
                  <Grid item xs={12} md={9}>
                    <Field name="search" component={RenderField} />
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.selectContainer}>
                    <Field name="type" component={RenderSelectField} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button type="submit" color="primary" variant="contained" fullWidth>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

FilterForm.propTypes = {
  searchRequest: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default FilterForm;
