import { Box, Button, Fade, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  confirmContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.palette.primary.lightBlue,
    color: theme.palette.common.white,
  },
  confirmButtonNo: {
    width: 96,
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  confirmButtonYes: {
    width: 96,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
}));

const Confirm = ({ show, onConfirm, onCancel }) => {
  const classes = useStyles();
  return (
    <Fade in={show}>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.confirmContainer}
      >
        <Box mb={2} fontWeight={600}>
          Are you sure you want to cancel the transaction?
        </Box>
        <Box>
          <Button
            variant="outlined"
            size="small"
            onClick={onCancel}
            className={classes.confirmButtonNo}
          >
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={onConfirm}
            className={classes.confirmButtonYes}
          >
            Yes
          </Button>
        </Box>
      </Grid>
    </Fade>
  );
};

Confirm.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Confirm;
