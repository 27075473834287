import { all, put, call, takeEvery } from 'redux-saga/effects';
import keyBy from 'lodash.keyby';

import * as types from './types';
import * as actions from './actions';
import * as api from '../api';

function* getPrintersRequest() {
  try {
    const { data } = yield call(api.getPrinters);

    const dataById = keyBy(data, 'id');

    yield put(actions.getPrintersSuccess(dataById));
  } catch (err) {
    console.error('getPrintersRequest', err);
  }
}

export default function* authSaga() {
  yield all([takeEvery(types.GET_PRINTERS_REQUEST, getPrintersRequest)]);
}
