import React from 'react';
import MaskedInput from 'react-text-mask';

const Mask = ({ inputRef, mask = [], ...rest }) => (
  <MaskedInput
    {...rest}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={mask}
    guide={false}
  />
);

export default Mask;
