import { IconButton, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import nameToLabel from '../../../../utils/nameToLabel';
import EditButton from '../EditButton';
import SvgSymbol from '../../../_atoms/InlineSvg';
import * as actions from '../../state/actions';
import cropText from '../../../../utils/cropText';

const NotificationRow = ({ row, cellProps }) => {
  const dispatch = useDispatch();

  const editItem = ({ values, callback }) =>
    dispatch(actions.editNotificationRequest({ values, callback }));

  return (
    <TableRow>
      <TableCell {...cellProps}>{nameToLabel(row.type)}</TableCell>
      <TableCell {...cellProps}>{cropText(row.name, 30)}</TableCell>
      <TableCell {...cellProps}>{cropText(row.text, 60)}</TableCell>
      <TableCell {...cellProps} align="right">
        <IconButton size="small">
          <EditButton
            buttonText={<SvgSymbol iconName="edit" width={15} height={15} fill="computerOFF" />}
            noIcon
            type={row.type}
            onSubmit={editItem}
            buttonProps={{
              size: 'small',
            }}
            initialValues={row}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default NotificationRow;
