/* eslint-disable */
import { get, post, patch, put, del } from '../../../api';
import { API_URIS } from '../../../config';

export const getUser = queryObj => get(`${API_URIS.users.users}${queryObj}`);

export const getRefills = () => get(API_URIS.refills.refills);

export const createNewUser = (infoUser, data) => post(API_URIS.users.users, infoUser, data);

export const patchUser = (query, infoUser, data) =>
  patch(`${API_URIS.users.users}${query}`, infoUser, data);

export const deleteUser = query => del(`${API_URIS.users.users}${query}`);

export const addCard = ({ userId, data }) =>
  post(`${API_URIS.users.users}/${userId}/${API_URIS.users.cards}`, data);

export const editCard = ({ cardId, userId, data }) =>
  patch(`${API_URIS.users.users}/${userId}/${API_URIS.users.cards}/${cardId}`, data);

export const deleteCard = ({ cardId, userId }) =>
  del(`${API_URIS.users.users}/${userId}/${API_URIS.users.cards}/${cardId}`);

export const addNote = ({ userId, data }) =>
  post(`${API_URIS.users.users}/${userId}/${API_URIS.users.notes}`, data);

export const editNote = ({ userId, noteId, data }) =>
  put(`${API_URIS.users.users}/${userId}/${API_URIS.users.notes}/${noteId}`, data);

export const deleteNote = ({ noteId, userId }) =>
  del(`${API_URIS.users.users}/${userId}/${API_URIS.users.notes}/${noteId}`);

export const refillInvoice = (info, data) => post(`${API_URIS.invoices.invoices}`, info, data);

export const getInvoice = id => get(`${API_URIS.invoices.invoices}/${id}`);

export const editInvoice = ({ data, id }) => patch(`${API_URIS.invoices.invoices}/${id}`, data);

export const postPayment = ({ data, id }) =>
  post(`${API_URIS.invoices.invoices}/${id}${API_URIS.invoices.payments}`, data);
