import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ModalDialog from '../../../_common/Dialog';
import Form from './Form';

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    '& button': {
      minWidth: 130,
      '&:first-of-type': {
        marginRight: theme.spacing(3),
      },
    },
  },
}));

const AddButton = ({ buttonText = 'Edit', onSubmit, buttonProps, initialValues }) => {
  const classes = useStyles();

  const formRef = useRef();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit'));
    }
  };

  return (
    <ModalDialog
      title={`Edit ${initialValues.type}`}
      submitLabel="Save"
      onHandleSubmit={handleSubmit}
      BtnShowDialogTitle={buttonText}
      buttonProps={{
        ...buttonProps,
      }}
      dialogActionsProps={{
        className: classes.actions,
      }}
      Content={({ closeDialog }) => (
        <Form
          formRef={formRef}
          closeDialog={closeDialog}
          onSubmit={onSubmit}
          initial={initialValues}
        />
      )}
    />
  );
};

export default AddButton;
