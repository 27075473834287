import PropTypes from 'prop-types';

export const TypeCard = PropTypes.shape({
  cardNumber: PropTypes.string,
  expMonth: PropTypes.number,
  expYear: PropTypes.number,
  cvc: PropTypes.number,
});

export const TypeUser = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  gender: PropTypes.string,
  autoRefill: PropTypes.number,
  phone: PropTypes.string,
});

export const TypeinvoiceToItems = PropTypes.shape({
  amount: PropTypes.number,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    type: PropTypes.string,
  }),
});

export const TypePayments = PropTypes.shape({
  id: PropTypes.number,
  amount: PropTypes.number,
  method: PropTypes.string,
});

export const TypeInvoice = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invoiceToItems: PropTypes.arrayOf(TypePayments),
  payments: PropTypes.arrayOf(TypeinvoiceToItems),
  client: PropTypes.string,
  createdAt: PropTypes.string,
  total: PropTypes.number,
  received: PropTypes.number,
  change: PropTypes.number,
});
