import { Box, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import SvgSymbol from '../../../_atoms/InlineSvg';
import ModalDialog from '../../../_common/Dialog';
import * as actions from '../../state/actions';
import AddButton from '../AddButton';

const ActionButtons = ({ item }) => {
  const dispatch = useDispatch();

  const deleteItem = ({ callback }) => dispatch(actions.deleteItemRequest({ item, callback }));

  const editItem = ({ values, callback }) => {
    // console.log('values', values);
    dispatch(actions.editItemRequest({ values, callback }));
  };
  // console.log('item', item);
  return (
    <>
      <Box component="span" mr={1}>
        <IconButton size="small">
          <AddButton
            buttonText={<SvgSymbol iconName="edit" width={15} height={15} fill="computerOFF" />}
            noIcon
            type={item.type}
            onSubmit={editItem}
            buttonProps={{
              size: 'small',
            }}
            initialValues={item}
          />
        </IconButton>
      </Box>
      <ModalDialog
        title="Are you sure you want to delete this item?"
        submitLabel="Delete"
        onHandleSubmit={deleteItem}
        BtnShowDialogTitle={
          <SvgSymbol iconName="close" width={15} height={15} fill="computerOFF" />
        }
        Content={() => (
          <Box textAlign="center">
            <Typography component="span" style={{ fontWeight: 'bold' }} variant="body1">
              {item.name}
            </Typography>
            <Typography color="textSecondary" component="span" style={{ fontWeight: 'bold' }}>
              {` | $${item.price}`}
            </Typography>
          </Box>
        )}
        color="secondary"
        buttonProps={{
          size: 'small',
        }}
      />
    </>
  );
};

export default ActionButtons;
