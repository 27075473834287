import { palette } from './palette';
import { ProximaNovaRegular } from './typography/fonts';

const globalStyle = {
  '@font-face': [ProximaNovaRegular],
  body: {
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  a: {
    textDecoration: 'none',
  },
  '*': {
    scrollBehavior: 'smooth',
  },
  '.loadingErrorContainer': {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  '.capitalize': {
    textTransform: 'capitalize',
  },
  '.svg-icon': {
    verticalAlign: 'text-top',
    marginRight: 16,
  },
  '.deleteButton': {
    color: `${palette.primary.red} !important`,
    '&:hover': {
      // backgroundColor: colors.redLight,
    },
  },
  '.fontWeightNormal': {
    fontWeight: 'normal !important',
  },
  '.search': {
    fontSize: 14,
    backgroundColor: palette.primary.light,
    paddingLeft: 8,
    paddingRight: 8,
    padding: 8,
    border: `0 !important`,
    '& img': {
      paddingRight: 8,
    },
    '& input': {
      padding: 0,
      height: 'unset',
      '&::placeholder': {
        color: palette.primary.main,
      },
    },
    '&.Mui-focused': {
      border: `0 !important`,
    },
  },
  '.checkoutWrapperItem': {
    border: `1px solid ${palette.borders.main}`,
    padding: '8px 12px',
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: palette.primary.main,
  },
  '.checkoutSummary': {
    display: 'flex',
    padding: `16px 20px`,
    alignItems: 'center',
  },
  '.checkoutContent': {
    // display: 'flex',
    // flexDirection: 'column',
    padding: `0 20px`,
    // alignItems: 'flex-start',
  },
  '.backgroundLightest': {
    backgroundColor: `${palette.primary.lightestBlue} !important`,
  },
  // for buttons
  '.minWidth130': {
    minWidth: 130,
  },
  // for notifications
  '.MuiSnackbarContent-root': {
    maxWidth: 350,
  },
  '[class*="SnackbarItem-base"]': {
    flexWrap: 'unset !important',
  },
  '.itemNumInput': {
    color: `${palette.primary.main} !important`,
    '&::placeholder': {
      color: palette.primary.lightBlue,
    },
  },
  '.priceInput': {
    position: 'relative',
    '&:before': {
      content: "'$'",
      position: 'absolute',
      left: 10,
    },
  },
};

export default globalStyle;
