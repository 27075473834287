import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOrError from '../../_atoms/LoadingOrError';
import ComputersList from '../components/ComputersList';

import * as actions from '../state/actions';
import * as selectors from '../state/selectors';
import * as selectorsProfile from '../../profile/state/selectors';

const ComputersContainer = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectors.computers);
  const dataLoading = useSelector(selectors.computersLoading);
  const { role } = useSelector(selectorsProfile.profile);

  const isAdmin = role === 'ADMIN';

  useEffect(() => {
    dispatch(actions.getAllPCRequest());
  }, []);

  const startSession = val => dispatch(actions.startSessionRequest(val));

  const endSession = val => dispatch(actions.endSessionRequest(val));

  const transferUser = val => dispatch(actions.transferUserRequest(val));

  const onHandlePcEdit = ({ values, compId, successMessage, callback }) => {
    dispatch(
      actions.editPcRequest({
        compId,
        data: values,
        successMessage,
        callback,
      }),
    );
  };

  const arrange = positions => {
    dispatch(actions.arrangeComputersRequest(positions));
  };

  if (dataLoading) {
    return <LoadingOrError style={{ height: '50vh' }} />;
  }

  return (
    <ComputersList
      data={data}
      startSession={startSession}
      endSession={endSession}
      transferUser={transferUser}
      onHandlePcEdit={onHandlePcEdit}
      arrange={arrange}
      isAdmin={isAdmin}
    />
  );
};

export default ComputersContainer;
