import keyBy from 'lodash.keyby';
import { all, call, debounce, put, takeEvery } from 'redux-saga/effects';

import getStringFromError from '../../../utils/getStringFromError';
import { objToQueryString } from '../../../utils/query';
import showNotification from '../../../utils/showNotification';
import * as apiUser from '../../users/api';
import * as api from '../api';
import * as actions from './actions';
import * as types from './types';

function* getAllPCRequest() {
  try {
    const { data } = yield call(api.computers);

    const dataById = keyBy(data, 'id');

    yield put(actions.getAllPCSuccess({ data: dataById }));
  } catch (err) {
    console.error('getAllPCRequest', err);
  }
}

function* getUsersRequest({ payload }) {
  try {
    const query = `?${objToQueryString(payload)}`;

    const { data } = yield call(apiUser.getUser, query);

    yield put(actions.getUsersSuccess(data));
  } catch (err) {
    console.error('getUsersRequest', err);
  }
}

function* startSessionRequest({ payload }) {
  try {
    const { data } = yield call(api.startSession, payload.data);

    yield put(showNotification('Session started.', 'success'));

    if (payload.callback) {
      payload.callback();
    }

    yield put(actions.startSessionSuccess(data));
  } catch (err) {
    console.error('startSessionRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* endSessionRequest(action) {
  try {
    const { callback, ...payload } = action.payload;

    const { data } = yield call(api.endSession, payload);

    if (callback) {
      callback();
    }

    yield put(showNotification('Session ended.', 'success'));

    yield put(actions.endSessionSuccess({ userId: payload.userId, computer: data }));
  } catch (err) {
    console.error('endSessionRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* transferUserRequest(action) {
  try {
    const { callback, ...payload } = action.payload;

    const { data } = yield call(api.transferUser, payload);

    if (callback) {
      callback();
    }

    yield put(showNotification('User transferred.', 'success'));

    yield put(actions.transferUserSuccess({ computer: data, oldComputer: payload.computerId }));
  } catch (err) {
    console.error('transferUserRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* editPcRequest({ payload }) {
  try {
    const { successMessage, compId, callback, ...values } = payload;
    const { data } = yield call(api.editComputer, { compId, ...values });
    const result = [compId].reduce((obj, id) => ({ ...obj, [id]: data }), {});

    if (callback) {
      callback();
    }

    yield put(actions.editPcSuccess(result));

    yield put(showNotification(successMessage, 'success'));
  } catch (err) {
    console.error('editPcRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* arrangePcRequest({ payload }) {
  try {
    const allResponses = yield all(
      Object.keys(payload).map(pc => call(api.editComputer, { compId: pc, data: payload[pc] })),
    );

    yield all(
      allResponses.map(response => put(actions.arrangeSingleComputerSuccess(response.data))),
    );

    yield put(showNotification('Positions updated', 'success'));
  } catch (err) {
    console.error('arrangePcRequest', err);
    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(types.GET_ALL_PC_REQUEST, getAllPCRequest),
    debounce(200, types.GET_USERS_PC_REQUEST, getUsersRequest),
    takeEvery(types.START_SESSION_REQUEST, startSessionRequest),
    takeEvery(types.END_SESSION_REQUEST, endSessionRequest),
    takeEvery(types.TRANSFER_USER_REQUEST, transferUserRequest),
    takeEvery(types.EDIT_PC_REQUEST, editPcRequest),
    takeEvery(types.ARRANGE_PC_REQUEST, arrangePcRequest),
  ]);
}
