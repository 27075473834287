import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import TableRowNotification from '../components/NotificationRow';
import TableWithCustomRows from '../../_common/TableWithCustomRows';

import * as actions from '../state/actions';
import * as selectors from '../state/selectors';

const NotificationsContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getNotificationsRequest());
  }, []);

  const data = useSelector(selectors.notifications);

  // const data = {
  //   1: {
  //     type: 'Pop-up',
  //     name: '5 min. Alert',
  //     title: '5 min. Alert',
  //     text: 'Text here',
  //     id: 1,
  //   },
  //   2: {
  //     type: 'Pop-up',
  //     name: '5 min. closing',
  //     title: '5 min. Alert',
  //     text: 'Text here',
  //     id: 2,
  //   },
  //   3: {
  //     type: 'Email',
  //     name: 'Welcome Email',
  //     title: 'Welcome Email',
  //     text: 'Text here',
  //     id: 3,
  //   },
  //   4: {
  //     type: 'Email',
  //     name: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  //     title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  //     text:
  //       'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid culpa cupiditate, deleniti\n' +
  //       'doloremque ea eaque earum fugit in inventore libero modi necessitatibus odio quaerat quisquam\n' +
  //       'quo ratione similique tempore ut.',
  //     id: 4,
  //   },
  // };

  return (
    <TableWithCustomRows
      data={Object.values(data)}
      title={<Box mb={1}>Notifications</Box>}
      headings={['Type', 'Name', 'Text', '']}
      TableRow={TableRowNotification}
    />
  );
};
export default NotificationsContainer;
