import { Box, Button, Collapse, Divider, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import calculatePrice from '../../../../utils/calculatePrice';
import capitalize from '../../../../utils/capitalize';
import * as selectorsProfile from '../../../profile/state/selectors';
import { TypeInvoice } from '../../../../types';
import Confirm from './Confirm';

const useStyles = makeStyles(theme => ({
  wrapper: ({ gridTemplateColumns }) => ({
    display: 'grid',
    position: 'relative',
    gridTemplateColumns,
  }),
  wrapperInner: {
    gridArea: '2 / 5 / 2 / 7',
    backgroundColor: `transparent !important`,
    padding: `${theme.spacing(2)}px 0 `,
  },
  innerRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 50px 80px',
    fontSize: 12,
  },
  innerCell: {
    padding: `0 ${theme.spacing(2)}px`,
    fontWeight: 600,
    color: theme.palette.grey.A700,
  },
  amount: {
    fontSize: '.8em',
    color: theme.palette.grey[600],
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}px`,
  },
}));

/**
 * Component shows invoice details. It maps invoiceToItems and payments.
 *
 * When displaying payments, it handels case when items were overpaid.
 * For example: total was $10 but cash recieved was $20 - table will show Total: $10.00.
 *
 */

const Details = ({
  isExpanded,
  gridTemplateColumns,
  invoice,
  setShowConfirm,
  cancel,
  showConfirm,
}) => {
  const classes = useStyles({ gridTemplateColumns, isExpanded });

  const { role } = useSelector(selectorsProfile.profile);
  const isAdmin = role === 'ADMIN';

  return (
    <Collapse
      in={isExpanded}
      classes={{
        wrapper: classes.wrapper,
        wrapperInner: classes.wrapperInner,
      }}
    >
      {invoice.invoiceToItems.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className={classes.innerRow}>
          <div className={classes.innerCell}>
            {item?.item?.name}
            {item?.amount > 1 && <span className={classes.amount}>{` x${item?.amount}`}</span>}
          </div>
          <div />
          <div className={classes.innerCell}>{`$${calculatePrice(item?.item, 1).toFixed(2)}`}</div>
        </div>
      ))}
      <Box my={1}>
        <Divider />
      </Box>
      {invoice.payments.map((payment, i) => (
        <div key={payment.id} className={classes.innerRow}>
          <div className={classes.innerCell}>
            Payment type:
            <Typography color="primary" component="span" variant="body2">
              {` ${capitalize(payment.method)}`}
            </Typography>
          </div>
          <div className={classes.innerCell}>Total:</div>
          <div className={classes.innerCell}>
            $
            {(invoice.payments.length - 1 === i
              ? invoice.total -
                invoice.payments.reduce(
                  (acc, item, index) =>
                    invoice.payments.length - 1 !== index ? acc + item.amount : acc,
                  0,
                )
              : payment.amount
            ).toFixed(2)}
          </div>
        </div>
      ))}
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowConfirm(true)}
          disabled={!isAdmin}
          size="small"
        >
          Cancel transaction
        </Button>
        <Button variant="contained" color="primary" size="small">
          Print receipt
        </Button>
      </div>
      <Confirm show={showConfirm} onConfirm={cancel} onCancel={() => setShowConfirm(false)} />
    </Collapse>
  );
};

Details.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  gridTemplateColumns: PropTypes.string.isRequired,
  invoice: TypeInvoice.isRequired,
  setShowConfirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  showConfirm: PropTypes.bool.isRequired,
};

export default Details;
