import * as types from './types';

export const allInvoicesRequest = payload => {
  return {
    type: types.ALL_INVOICES_REQUEST,
    payload,
  };
};

export const allInvoicesSuccess = payload => {
  return {
    type: types.ALL_INVOICES_SUCCESS,
    payload,
  };
};

export const allInvoicesReset = payload => {
  return {
    type: types.ALL_INVOICES_RESET,
    payload,
  };
};

export const deleteInvoiceRequest = payload => {
  return {
    type: types.DELETE_INVOICES_REQUEST,
    payload,
  };
};

export const deleteInvoiceSuccess = payload => {
  return {
    type: types.DELETE_INVOICES_SUCCESS,
    payload,
  };
};
