import * as types from './types';

export const getItemsRequest = payload => ({
  type: types.GET_ITEMS_REQUEST,
  payload,
});

export const getItemsSuccess = payload => ({
  type: types.GET_ITEMS_SUCCESS,
  payload,
});

export const addItemRequest = payload => ({
  type: types.ADD_ITEM_REQUEST,
  payload,
});

export const addItemSuccess = payload => ({
  type: types.ADD_ITEM_SUCCESS,
  payload,
});

export const editItemRequest = payload => ({
  type: types.EDIT_ITEM_REQUEST,
  payload,
});

export const editItemSuccess = payload => ({
  type: types.EDIT_ITEM_SUCCESS,
  payload,
});

export const deleteItemRequest = payload => ({
  type: types.DELETE_ITEM_REQUEST,
  payload,
});

export const deleteItemSuccess = payload => ({
  type: types.DELETE_ITEM_SUCCESS,
  payload,
});
