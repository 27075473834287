const cropTimeString = timeString => {
  let timeToShow = timeString;
  if (timeToShow) {
    const seconds = timeToShow.substr(-2);
    // round up to minutes
    if (seconds !== '00') {
      const minutes = timeToShow.substr(-5, 2);
      if (parseInt(minutes, 10) + 1 !== 60) {
        timeToShow = timeToShow
          .replace(/:\d+:/, `:${String(parseInt(minutes, 10) + 1).padStart(2, '0')}:`)
          .replace(/:\d+$/, `:00`);
      } else {
        const stringHours = timeToShow.match(/\d+/);

        const newHours =
          String(parseInt(stringHours[0], 10) + 1).length < 2
            ? String(parseInt(stringHours[0], 10) + 1).padStart(2, '0')
            : parseInt(stringHours[0], 10) + 1;
        timeToShow = timeToShow
          .replace(/\d+:/, `${newHours}:`)
          .replace(/:\d+:/, `:00:`)
          .replace(/:\d+$/, `:00`);
      }
    }
  }

  return timeToShow ? timeToShow.slice(0, -3) : '00:00';
};

export default cropTimeString;
