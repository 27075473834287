import { combineReducers } from 'redux';
import authReducer from '../../app/auth/state/reducer';
import userReducer from '../../app/users/state/reducer';
import profileReducer from '../../app/profile/state/reducer';
import layoutReducer from '../../app/layout/state/reducer';
import itemsReducer from '../../app/items/state/reducer';
import notificationsReducer from '../../app/notifications/state/reducer';
import invoicesReducer from '../../app/invoices/state/reducer';
import posReducer from '../../app/pos/state/reducer';
import computersReducer from '../../app/computers/state/reducer';
import printersReducer from '../../app/printers/state/reducer';

const reducers = combineReducers({
  auth: authReducer,
  users: userReducer,
  profile: profileReducer,
  ui: layoutReducer,
  items: itemsReducer,
  invoices: invoicesReducer,
  notifications: notificationsReducer,
  pos: posReducer,
  computers: computersReducer,
  printers: printersReducer,
});

export default reducers;
