import { makeStyles, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  row: {
    backgroundColor: theme.palette.primary.lightestBlue,
  },
  cell: {
    color: theme.palette.primary.lightBlue,
    fontWeight: 600,
    borderBottomColor: theme.palette.primary.lightestBlue,
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
  },
}));

const TableHeadRow = ({ cells }) => {
  const classes = useStyles();

  return (
    <TableHead className={classes.row}>
      <TableRow>
        {cells.map((cell, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableCell key={i} className={classes.cell}>
            {cell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadRow;
