import { makeStyles, Box, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import { useDispatch } from 'react-redux';

import ComputerItem from './ComputerItem';
import * as actions from '../../state/actions';
import editIcon from '../../../../assets/images/icons/edit.svg';

const cell = 24;

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: 'calc(100% - 36px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  container: {
    maxWidth: 672,
    margin: 'auto',
    textAlign: 'right',
  },
  gridContainer: ({ isAdmin, editMode }) => ({
    height: cell * 28,
    width: cell * 28,
    backgroundSize: `${cell}px ${cell}px`,
    backgroundImage: editMode
      ? `linear-gradient(to right, ${theme.palette.primary.lightBlue} 1px, transparent 1px), linear-gradient(to bottom, ${theme.palette.primary.lightBlue} 1px, transparent 1px)`
      : 'none',
    border: isAdmin ? `1px solid ${theme.palette.primary.lightBlue}` : 'none',
  }),
}));

const ComputersList = ({
  data,
  startSession,
  endSession,
  transferUser,
  onHandlePcEdit,
  arrange,
  isAdmin,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [positions, setPositions] = useState(data);

  const classes = useStyles({ isAdmin, editMode });

  useEffect(() => {
    setPositions(data);
  }, [data]);

  const handleSave = () => {
    // we wanna send only changed data
    // changed object has only two values (x and y)
    const positionsToSend = Object.keys(positions).reduce((acc, cur) => {
      if (Object.keys(positions[cur]).length === 2) {
        acc[cur] = positions[cur];
      }
      return acc;
    }, {});

    arrange(positionsToSend);
    setEditMode(false);
    dispatch(actions.setArrangeMode(false));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.gridContainer}>
          {Object.values(positions).length &&
            Object.values(data).map(pc => (
              <Rnd
                key={pc.id}
                // default={{ x: pc.x, y: pc.y, width: 64, height: 80 }}
                onDragStop={(_e, d) => {
                  // console.log(d.x, d.y);
                  setPositions(old => ({
                    ...old,
                    [pc.id]: {
                      // x: Math.round(d.x),
                      // y: Math.round(d.y),
                      x: d.x,
                      y: d.y,
                    },
                  }));
                }}
                // dragGrid={[cell, cell]}
                position={{ x: positions[pc.id].x, y: positions[pc.id].y }}
                size={{ width: cell * 3, height: cell * 4 }}
                resizable={false}
                bounds="parent"
                disableDragging={!editMode}
                enableResizing={false}
              >
                <ComputerItem
                  pc={pc}
                  endSession={endSession}
                  startSession={startSession}
                  transferUser={transferUser}
                  onHandlePcEdit={onHandlePcEdit}
                  editMode={editMode}
                />
              </Rnd>
            ))}
        </div>
        {isAdmin && (
          <Box alignSelf="flex-end" mt={1}>
            {editMode ? (
              <>
                <Box component="span" mr={1}>
                  <Button
                    onClick={() => {
                      setEditMode(false);
                      dispatch(actions.setArrangeMode(false));
                      setPositions(data);
                    }}
                    color="primary"
                    variant="outlined"
                    className="minWidth130"
                  >
                    Cancel
                  </Button>
                </Box>
                <Button
                  onClick={handleSave}
                  color="primary"
                  variant="contained"
                  className="minWidth130"
                >
                  Save
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  setEditMode(true);
                  dispatch(actions.setArrangeMode(true));
                }}
                color="primary"
                endIcon={<img src={editIcon} alt="" />}
              >
                Arrange computers
              </Button>
            )}
          </Box>
        )}
      </div>
    </div>
  );
};

export default ComputersList;
