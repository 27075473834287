import { color } from '../theme';

const MuiExpansionPanelSummary = {
  root: {
    '&$expanded': {
      minHeight: 48,
    },

    '& svg.arrow': {
      width: 16,
      height: 9,
      fill: `${color.blue}`,
    },
  },
  content: {
    margin: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    '&$expanded': {
      margin: 0,
    },
  },
};

export default MuiExpansionPanelSummary;
