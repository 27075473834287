import { get, post, patch } from '../../../api';
import { API_URIS } from '../../../config';

// eslint-disable-next-line import/prefer-default-export
export const computers = () => get(`${API_URIS.computers.computers}`);

export const startSession = ({ computerId, userId }) =>
  post(`${API_URIS.sessions.sessions}${computerId}/${API_URIS.sessions.users}${userId}`);
export const endSession = ({ computerId, userId }) =>
  post(
    `${API_URIS.sessions.sessions}${computerId}/${API_URIS.sessions.users}${userId}/${API_URIS.sessions.finish}`,
  );

export const transferUser = ({ computerId, userId, data }) =>
  patch(`${API_URIS.sessions.sessions}${computerId}/${API_URIS.sessions.users}${userId}`, data);

export const editComputer = ({ compId, data }) =>
  patch(`${API_URIS.computers.computers}/${compId}`, data);
