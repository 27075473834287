import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { queryStringToObj, objToQueryString } from '../../../utils/query';

/**
 * Helps navigate within user panel: keeps current path and adds user route info.
 * */
const LinkKeepPathname = ({ query, prefix = '&', children, ...rest }) => {
  const { search, pathname } = useLocation();

  let newQuery = '';

  if (query.includes('=')) {
    const searchObj = queryStringToObj(search);
    const queryObj = queryStringToObj(`?${query}`);

    newQuery = objToQueryString({ ...searchObj, ...queryObj });

    return (
      <Link to={`${pathname}?${newQuery}`} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <Link to={`${pathname}${search ? `${search}${prefix}${query}` : `?${query}`}`} {...rest}>
      {children}
    </Link>
  );
};

LinkKeepPathname.defaultProps = {
  prefix: '&',
};

LinkKeepPathname.propTypes = {
  children: PropTypes.node.isRequired,
  query: PropTypes.string.isRequired,
  prefix: PropTypes.string,
};

export default LinkKeepPathname;
