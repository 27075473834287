import { all, spawn } from 'redux-saga/effects';
import authSaga from '../../app/auth/state/saga';
import usersSaga from '../../app/users/state/saga';
import profileSaga from '../../app/profile/state/saga';
import itemsSaga from '../../app/items/state/saga';
import invoicesSaga from '../../app/invoices/state/saga';
import notificationsSaga from '../../app/notifications/state/saga';
import posSaga from '../../app/pos/state/saga';
import printersSaga from '../../app/printers/state/saga';
import computersSaga from '../../app/computers/state/saga';
import socketSaga from '../../app/computers/state/socket';

export default function* rootSaga() {
  yield all([
    spawn(authSaga),
    spawn(usersSaga),
    spawn(profileSaga),
    spawn(itemsSaga),
    spawn(invoicesSaga),
    spawn(notificationsSaga),
    spawn(posSaga),
    spawn(computersSaga),
    spawn(printersSaga),
    spawn(socketSaga),
  ]);
}
