import { TableCell, TableRow, Box, Checkbox } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '../../state/actions';
import ActionButtons from '../ActionButtons';

const TableRowTime = ({ row, cellProps }) => {
  const dispatch = useDispatch();

  const editItem = e => {
    dispatch(
      actions.editItemRequest({
        values: {
          id: row.id,
          name: row.name,
          [e.target.name]: e.target.checked,
        },
      }),
    );
  };

  return (
    <TableRow key={row.id}>
      <TableCell {...cellProps}>{row.name}</TableCell>
      <TableCell {...cellProps}>{row.hours}</TableCell>
      <TableCell {...cellProps}>
        <Box textAlign="right" display="inline-block" minWidth={74}>
          {row.promo}
          <Box ml={2} component="span">
            <Checkbox name="usePromo" defaultChecked={row.usePromo} onChange={editItem} />
          </Box>
        </Box>
      </TableCell>
      <TableCell {...cellProps}>{`$${row.price}`}</TableCell>
      <TableCell {...cellProps}>
        <Box textAlign="right" display="inline-block" minWidth={74}>
          {`$${row.discount}`}
          <Box ml={2} component="span">
            <Checkbox name="useDiscount" defaultChecked={row.useDiscount} onChange={editItem} />
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right">
        <ActionButtons item={row} />
      </TableCell>
    </TableRow>
  );
};

export default TableRowTime;
