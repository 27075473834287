const fieldArray = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    disabled: true,
    labelGridSize: 3,
    fieldGridSize: 9,
  },
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    labelGridSize: 3,
    fieldGridSize: 9,
  },
  {
    name: 'text',
    label: 'Text message',
    type: 'text',
  },
];

export default fieldArray;
