/* eslint-disable */
import { Box, Divider, Tab, Tabs } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import nameToLabel from '../../../utils/nameToLabel';
import AddButton from '../components/AddButton';
import TableRowItems from '../components/ItemsRows/TableRowItems';
import TableRowPrints from '../components/ItemsRows/TableRowPrints';
import TableRowTime from '../components/ItemsRows/TableRowTime';
import TabPanel from '../components/TabPanel';

import * as actions from '../state/actions';
import * as selectors from '../state/selectors';
import TableWithCustomRows from '../../_common/TableWithCustomRows';

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 60,
    fontWeight: 600,
    marginRight: theme.spacing(1),
    color: theme.palette.primary.lightBlue,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
}));

const ItemsContainer = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getItemsRequest());
  }, []);

  const data = useSelector(selectors.itemsAll);

  const tableTypes = [
    {
      type: 'TIME',
      headCellsArray: ['Name', 'Hours', 'Promo', 'Price', 'Discount', ''],
      rowComponent: TableRowTime,
    },
    {
      type: 'FAX_AND_COPY',
      headCellsArray: ['Name', 'Printer', 'Price', ''],
      rowComponent: TableRowPrints,
    },
    {
      type: 'ACCESSORIES',
      headCellsArray: ['Name', 'Price', ''],
      rowComponent: TableRowItems,
    },
    {
      type: 'PACKAGES',
      headCellsArray: ['Name', 'Price', ''],
      rowComponent: TableRowItems,
    },
  ];

  const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addItem = ({ values, callback }) => dispatch(actions.addItemRequest({ values, callback }));

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
        >
          <Tab label="All" {...a11yProps(0)} classes={{ root: classes.root }} />
          {tableTypes.map((type, i) => (
            <Tab
              key={type.type}
              label={nameToLabel(type.type)}
              {...a11yProps(i + 1)}
              classes={{ root: classes.root }}
            />
          ))}
        </Tabs>
        <AddButton onSubmit={addItem} initialValues={{ isHoursEditable: true }} />
      </Grid>
      <TabPanel value={value} index={0}>
        {tableTypes.map((type, i) => (
          <TableWithCustomRows
            key={type.type}
            BeforeTable={
              <Box mt={i === 0 ? 0 : 3} mb={4}>
                <Divider />
              </Box>
            }
            TitleButton={
              <AddButton
                buttonText={`Add ${nameToLabel(type.type).toLowerCase()}`}
                initialValues={{ type: type.type, isHoursEditable: true }}
                onSubmit={addItem}
              />
            }
            title={nameToLabel(type.type)}
            data={data[type.type] || []}
            headings={type.headCellsArray}
            TableRow={type.rowComponent}
          />
        ))}
      </TabPanel>
      {tableTypes.map((type, i) => (
        <TabPanel value={value} index={i + 1} key={type.type}>
          <TableWithCustomRows
            BeforeTable={
              <Box mb={4}>
                <Divider />
              </Box>
            }
            TitleButton={
              <AddButton
                buttonText={`Add ${nameToLabel(type.type).toLowerCase()}`}
                initialValues={{ type: type.type }}
                onSubmit={addItem}
              />
            }
            title={nameToLabel(type.type)}
            data={data[type.type]}
            headings={type.headCellsArray}
            TableRow={type.rowComponent}
          />
        </TabPanel>
      ))}
    </>
  );
};
export default ItemsContainer;
