const MuiDialogContent = {
  root: {
    '&:not(.MuiPickersModal-dialog)': {
      padding: 0,
      marginBottom: 32,
      overflow: 'hidden',

      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
};

export default MuiDialogContent;
