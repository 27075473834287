import { all, call, takeLatest, take, put, select, fork } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import io from 'socket.io-client';

import { BASE_URI } from '../../../config';
import * as selectors from './selectors';
import getStringFromError from '../../../utils/getStringFromError';
import showNotification from '../../../utils/showNotification';
import * as apiUsers from '../../users/api';
import * as actions from './actions';
import * as types from './types';

const connect = () => {
  return io(BASE_URI, {
    // path: SOCKET_PATH,
  }).on('connect', function() {
    this.emit('sessions', { action: 'JOIN' });
  });
};

const reconnect = socket =>
  new Promise(resolve => {
    socket.on('reconnect', () => {
      resolve(socket);
    });
  });

function* resumeReconnection() {
  const isArrangeMode = yield select(selectors.isArrangeMode);
  if (!isArrangeMode) {
    yield put(actions.getAllPCRequest());
  }
}

function* listenConnectSaga(socket) {
  while (true) {
    yield call(reconnect, socket);
    yield* resumeReconnection(socket);
  }
}

const socketEventHandlers = {
  sessionStarted: actions.wsSessionStarted,
  sessionFinished: actions.wsSessionFinished,
  computerStatusChanged: actions.wsСomputerStatusChanged,
};

// This is how a channel is created
const createSocketChannel = socket =>
  eventChannel(emit => {
    const handler = action => data => {
      emit(action(data));
    };
    const setSocketEventListeners = method =>
      Object.keys(socketEventHandlers).forEach(event => {
        socket[method](event, handler(socketEventHandlers[event]));
      });

    setSocketEventListeners('on');

    return () => {
      setSocketEventListeners('off');
    };
  });

// saga that listens to the socket and puts the new data into the reducer
function* listenServerSaga() {
  // connect to the server
  const socket = yield call(connect);
  // then create a socket channel
  const socketChannel = yield call(createSocketChannel, socket);
  yield fork(listenConnectSaga, socket);
  // then put the new data into the reducer
  while (true) {
    const action = yield take(socketChannel);
    yield put(action);
  }
}

function* wsSessionChange(status, { payload }) {
  try {
    // const isStart = status === 'IN_USE';

    const computers = yield select(selectors.computers);

    if (!status && (payload.status === 'ON' || payload.status === 'OFF')) {
      const computer = { ...computers[payload.id] };

      const newPayload = {
        computer: {
          ...computers[payload.id],
          status: payload.status,
          user: null,
        },
      };

      if (payload.status === 'OFF' && computer?.user?.id) {
        yield put(actions.wsSessionFinishSuccess({ user: computer.user.id, ...newPayload }));
      }
      yield put(actions.wsСomputerStatusChangedSuccess(newPayload));
    } else if (status === 'IN_USE') {
      const { data } = yield call(apiUsers.getUser, `/${payload.user}`);

      const newPayload = {
        user: data,
        computer: {
          ...computers[payload.computer],
          user: data,
          status,
        },
      };

      yield put(actions.wsSessionStartSuccess(newPayload));
    } else if (status === 'ON') {
      const newPayload = {
        user: payload.user,
        computer: {
          ...computers[payload.computer],
          user: null,
          status,
        },
      };

      yield put(actions.wsSessionFinishSuccess(newPayload));
    }
  } catch (err) {
    console.error('wsSessionChange', err);

    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

export default function* saga() {
  yield all([
    yield takeLatest(types.WS_INITIALIZE_CHANNEL, listenServerSaga),
    yield takeLatest(types.WS_SESSION_STARTED, wsSessionChange, 'IN_USE'),
    yield takeLatest(types.WS_SESSION_FINISHED, wsSessionChange, 'ON'),
    yield takeLatest(types.WS_STATUS_CHANGED, wsSessionChange, ''),
  ]);
}
